import axios from '../../axios'
import * as actionTypes from './actionTypes'

const fetchDiscountsStart = () => {
  return {
    type: actionTypes.FETCH_DISCOUNTS_START,
  }
}

const fetchDiscountsSuccess = discounts => {
  return {
    type: actionTypes.FETCH_DISCOUNTS_SUCCESS,
    payload: discounts,
  }
}

const fetchDiscountsFailed = error => {
  return {
    type: actionTypes.FETCH_DISCOUNTS_FAILED,
    error,
  }
}

export const fetchDiscounts = () => async dispatch => {
  dispatch(fetchDiscountsStart())
  try {
    const { data } = await axios.get('/discounts')
    dispatch(fetchDiscountsSuccess(data.data))
  } catch (err) {
    dispatch(fetchDiscountsFailed(err))
  }
}

const createDiscountsSuccess = createdDiscountsCount => {
  return {
    type: actionTypes.CREATE_DISCOUNTS_SUCCESS,
    createdDiscountsCount,
  }
}

const createDiscountsFailed = error => {
  return {
    type: actionTypes.CREATE_DISCOUNTS_FAILED,
    error,
  }
}

export const createDiscounts = discounts => async dispatch => {
  try {
    const { data } = await axios.post('/discounts', {
      discounts: discounts
    })
    dispatch(createDiscountsSuccess(data.data.createdDiscountsCount))
  } catch (err) {
    dispatch(createDiscountsFailed(err))
  }
}

export const resetDiscountsUploaded = () => {
  return {
    type: actionTypes.RESET_DISCOUNTS_UPLOADED,
  }
}
