import React from 'react'
// @material-ui/core components
import Hidden from '@material-ui/core/Hidden'
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import Logout from '@material-ui/icons/ExitToApp'
// components
import Button from '../../UI/CustomButtons/Button'
// assets
import headerLinkStyle from '../../../assets/jss/components/headerLinkStyle'

class HeaderLinks extends React.Component {
  state = {
    open: false,
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Person"
          className={classes.buttonLink}
          onClick={this.props.onLogoutClick}
        >
          <Logout className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
      </div>
    )
  }
}

export default withStyles(headerLinkStyle)(HeaderLinks)
