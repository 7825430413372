// auth
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_START = 'AUTH_START';
export const LOGOUT = 'LOGOUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// branch offices
export const GET_BRANCH_OFFICES_DESCRIPTION = 'GET_BRANCH_OFFICES_DESCRIPTION';
export const SET_BRANCH_OFFICES_DESCRIPTION = 'SET_BRANCH_OFFICES_DESCRIPTION';
export const FETCH_BRANCH_OFFICES_DESCRIPTION_FAILED = 'FETCH_BRANCH_OFFICES_DESCRIPTION_FAILED';
export const EDIT_BRANCH_OFFICES_DESCRIPTION = 'EDIT_BRANCH_OFFICES_DESCRIPTION';
export const EDIT_BRANCH_OFFICES_DESCRIPTION_FAILED = 'EDIT_BRANCH_OFFICES_DESCRIPTION_FAILED';
export const GET_BRANCH_OFFICES = 'GET_BRANCH_OFFICES';
export const SET_BRANCH_OFFICES = 'SET_BRANCH_OFFICES';
export const FETCH_BRANCH_OFFICES_START = 'FETCH_BRANCH_OFFICES_START';
export const FETCH_BRANCH_OFFICES_FAILED = 'FETCH_BRANCH_OFFICES_FAILED';
export const CREATE_BRANCH_OFFICES_CSV_SUCCESS = 'CREATE_BRANCH_OFFICES_CSV_SUCCESS';
export const CREATE_BRANCH_OFFICES_CSV_FAILED = 'CREATE_BRANCH_OFFICES_CSV_FAILED';
export const CREATE_BRANCH_OFFICE = 'CREATE_BRANCH_OFFICE';
export const CREATE_BRANCH_OFFICE_FAILED = 'CREATE_BRANCH_OFFICE_FAILED';
export const EDIT_BRANCH_OFFICE = 'EDIT_BRANCH_OFFICE';
export const EDIT_BRANCH_OFFICE_FAILED = 'EDIT_BRANCH_OFFICE_FAILED';
export const DELETE_BRANCH_OFFICE = 'DELETE_BRANCH_OFFICE';
export const DELETE_BRANCH_OFFICE_FAILED = 'DELETE_BRANCH_OFFICE_FAILED';
export const GET_ZONES = 'GET_ZONES';
export const SET_ZONES = 'SET_ZONES';
export const FETCH_ZONES_FAILED = 'FETCH_ZONES_FAILED';

// documentation
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const FETCH_DOCUMENTS_FAILED = 'FETCH_DOCUMENTS_FAILED';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const CREATE_DOCUMENT_FAILED = 'CREATE_DOCUMENT_FAILED';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const EDIT_DOCUMENT_FAILED = 'EDIT_DOCUMENT_FAILED';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED';

// banners
export const FETCH_BANNERS_START = 'FETCH_BANNERS_START';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_FAILED = 'FETCH_BANNERS_FAILED';
export const CREATE_BANNER_FAILED = 'CREATE_BANNER_FAILED';
export const DELETE_BANNER_FAILED = 'DELETE_BANNER_FAILED';
export const EDIT_BANNER_FAILED = 'EDIT_BANNER_FAILED';

// characteristics
export const FETCH_CHARACTERISTICS_START = 'FETCH_CHARACTERISTICS_START';
export const FETCH_CHARACTERISTICS_SUCCESS = 'FETCH_CHARACTERISTICS_SUCCESS';
export const FETCH_CHARACTERISTICS_FAILED = 'FETCH_CHARACTERISTICS_FAILED';
export const CREATE_CHARACTERISTIC_FAILED = 'CREATE_CHARACTERISTIC_FAILED';
export const DELETE_CHARACTERISTIC_FAILED = 'DELETE_CHARACTERISTIC_FAILED';
export const EDIT_CHARACTERISTIC_FAILED = 'EDIT_CHARACTERISTIC_FAILED';

// promotions
export const FETCH_PROMOTIONS_START = 'FETCH_PROMOTIONS_START';
export const FETCH_PROMOTIONS_SUCCESS = 'FETCH_PROMOTIONS_SUCCESS';
export const FETCH_PROMOTIONS_FAILED = 'FETCH_PROMOTIONS_FAILED';
export const CREATE_PROMOTION_FAILED = 'CREATE_PROMOTION_FAILED';
export const DELETE_PROMOTION_FAILED = 'DELETE_PROMOTION_FAILED';
export const EDIT_PROMOTION_FAILED = 'EDIT_PROMOTION_FAILED';

// tourismOffers
export const FETCH_TOURISM_OFFERS_START = 'FETCH_TOURISM_OFFERS_START';
export const FETCH_TOURISM_OFFERS_SUCCESS = 'FETCH_TOURISM_OFFERS_SUCCESS';
export const FETCH_TOURISM_OFFERS_FAILED = 'FETCH_TOURISM_OFFERS_FAILED';
export const CREATE_TOURISM_OFFER_FAILED = 'CREATE_TOURISM_OFFER_FAILED';
export const DELETE_TOURISM_OFFER_FAILED = 'DELETE_TOURISM_OFFER_FAILED';
export const EDIT_TOURISM_OFFER_FAILED = 'EDIT_TOURISM_OFFER_FAILED';

// discounts
export const FETCH_DISCOUNTS_START = 'FETCH_DISCOUNTS_START';
export const FETCH_DISCOUNTS_SUCCESS = 'FETCH_DISCOUNTS_SUCCESS';
export const FETCH_DISCOUNTS_FAILED = 'FETCH_DISCOUNTS_FAILED';
export const CREATE_DISCOUNTS_FAILED = 'CREATE_DISCOUNTS_FAILED';
export const CREATE_DISCOUNTS_SUCCESS = 'CREATE_DISCOUNTS_SUCCESS';
export const RESET_DISCOUNTS_UPLOADED = 'RESET_DISCOUNTS_UPLOADED';

// agile purchases
export const FETCH_AGILE_PURCHASES_START = 'FETCH_AGILE_PURCHASES_START';
export const FETCH_AGILE_PURCHASES_SUCCESS = 'FETCH_AGILE_PURCHASES_SUCCESS';
export const FETCH_AGILE_PURCHASES_FAILED = 'FETCH_AGILE_PURCHASES_FAILED';
export const CREATE_AGILE_PURCHASES_FAILED = 'CREATE_AGILE_PURCHASES_FAILED';
export const CREATE_AGILE_PURCHASES_SUCCESS = 'CREATE_AGILE_PURCHASES_SUCCESS';
export const RESET_AGILE_PURCHASES_UPLOADED = 'RESET_AGILE_PURCHASES_UPLOADED';
export const DELETE_AGILE_PURCHASES_START = 'DELETE_AGILE_PURCHASES_START';
export const DELETE_AGILE_PURCHASES_FAILED = 'DELETE_AGILE_PURCHASES_FAILED';
export const DELETE_AGILE_PURCHASES_SUCCESS = 'DELETE_AGILE_PURCHASES_SUCCESS';

// rse
export const FETCH_RSE = 'FETCH_RSE';
export const FETCH_RSE_SUCCESS = 'FETCH_RSE_SUCCESS';
export const FETCH_RSE_FAILED = 'FETCH_RSE_FAILED';
export const EDIT_RSE_TEXT = 'EDIT_RSE_TEXT';
export const EDIT_RSE_TEXT_FAILED = 'EDIT_RSE_TEXT_FAILED';
// rse banners
export const CREATE_RSE_BANNER = 'CREATE_RSE_BANNER';
export const CREATE_RSE_BANNER_FAILED = 'CREATE_RSE_BANNER_FAILED';
export const EDIT_RSE_BANNER = 'EDIT_RSE_BANNER';
export const EDIT_RSE_BANNER_FAILED = 'EDIT_RSE_BANNER_FAILED';
export const DELETE_RSE_BANNER = 'DELETE_RSE_BANNER';
export const DELETE_RSE_BANNER_FAILED = 'DELETE_RSE_BANNER_FAILED';

// insurances
export const FETCH_INSURANCES_START = 'FETCH_INSURANCES_START';
export const FETCH_INSURANCES_SUCCESS = 'FETCH_INSURANCES_SUCCESS';
export const FETCH_INSURANCES_FAILED = 'FETCH_INSURANCES_FAILED';
export const CREATE_INSURANCE_FAILED = 'CREATE_INSURANCE_FAILED';
export const DELETE_INSURANCE_FAILED = 'DELETE_INSURANCE_FAILED';
export const EDIT_INSURANCE_FAILED = 'EDIT_INSURANCE_FAILED';

//faqs
export const FETCH_FAQS_START = 'FETCH_FAQS_START';
export const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS';
export const FETCH_FAQS_FAILED = 'FETCH_FAQS_FAILED';
export const CREATE_FAQ_FAILED = 'CREATE_FAQ_FAILED';
export const DELETE_FAQ_FAILED = 'DELETE_FAQ_FAILED';
export const EDIT_FAQ_FAILED = 'EDIT_FAQ_FAILED';

// toast
export const SET_TOAST = 'SET_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

// bankImage
export const FETCH_BANK_IMAGES_START = 'FETCH_BANK_IMAGES_START';
export const FETCH_BANK_IMAGES_SUCCESS = 'FETCH_BANK_IMAGES_SUCCESS';
export const FETCH_BANK_IMAGES_FAILED = 'FETCH_BANK_IMAGES_FAILED';
export const CREATE_BANK_IMAGE_FAILED = 'CREATE_BANK_IMAGE_FAILED';
export const DELETE_BANK_IMAGE_FAILED = 'DELETE_BANK_IMAGE_FAILED';
export const EDIT_BANK_IMAGE_FAILED = 'EDIT_BANK_IMAGE_FAILED';
