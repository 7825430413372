import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions'
// core components
import GridItem from '../../../components/UI/Grid/GridItem'
import GridContainer from '../../../components/UI/Grid/GridContainer'
import CustomInput from '../../../components/UI/CustomInput/CustomInput'
import Button from '../../../components/UI/CustomButtons/Button'
import Card from '../../../components/UI/Card/Card'
import CardBody from '../../../components/UI/Card/CardBody'
import CardFooter from '../../../components/UI/Card/CardFooter'
// @material-ui/icons
import placeholder from '../../../assets/images/placeholder.png'

class RseBannersForm extends Component {
  constructor(props) {
    super(props)
    this.editMode = this.props.location.state.editMode

    this.state = {
      banner: this.editMode
        ? { ...this.props.location.state.banner, file: null }
        : { name: '', description: '', linkUrl: '', file: null }
    }
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newBanner = { ...this.state.banner }
    newBanner[inputIdentifier] = event.target.value
    this.setState({ banner: newBanner })
  }

  fileChangedHandler = (event, inputIdentifier) => {
    const newBanner = {
      ...this.state.banner,
      [inputIdentifier]: event.target.files[0],
    }
    this.setState({ banner: newBanner })
  }

  handleSubmit = async () => {
    try {
      if (this.editMode) {
        await this.props.onEditRseBanner(this.state.banner)
        this.props.setToast('El banner RSE fue editado con éxito')
      } else {
        await this.props.onCreateRseBanner(this.state.banner)
        this.props.setToast('El banner RSE fue creado con éxito')
      }
      this.props.history.push({ pathname: '/rse' })
    } catch (error) {
      if (this.editMode) {
        this.props.setToast('Se produjo un error al editar el banner RSE', 'danger')
      } else {
        this.props.setToast('Se produjo un error al crear el banner RSE', 'danger')
      }
    }
  }

  render() {
    const img = this.state.banner.file
      ? URL.createObjectURL(this.state.banner.file)
      : this.editMode
      ? process.env.REACT_APP_BACKEND_BASE_URL + this.state.banner.imageUrl
      : placeholder

    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Nombre del banner para RSE"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.banner.name,
                    onChange: event => this.inputChangedHandler(event, 'name'),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Descripción"
                  id="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.banner.description,
                    onChange: event => this.inputChangedHandler(event, 'description'),
                  }}
                />
              </GridItem>
            </GridContainer>
            <h2 style={{ margin: '2rem 0' }}>Imagen</h2>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} sm={6} lg={3}>
                <img
                  src={img}
                  alt="Imagen banner"
                  style={{ width: '350px' }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} lg={3}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event, 'file')}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  style={{
                    fontSize: '1rem',
                    margin: '1rem 0',
                  }}
                />
              </GridItem>
            </GridContainer>
						<GridContainer justify="center">
            	<GridItem xs={12} sm={6}>
              	<CustomInput
                	labelText="Link / Url"
                  id="linkUrl"
                  formControlProps={{
                  	fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.banner.linkUrl,
	                  onChange: event => this.inputChangedHandler(event, 'linkUrl'),
                  }}
             		/>
            	</GridItem>
          	</GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              style={{ margin: '0px auto 25px auto' }}
              disabled={
                !(this.state.banner.file || this.state.banner.imageUrl)
              }
              onClick={this.handleSubmit}
            >
              {this.editMode ? 'ACTUALIZAR' : 'CREAR'} BANNER RSE
            </Button>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateRseBanner: banner => dispatch(actions.createRseBanner(banner)),
  onEditRseBanner: banner => dispatch(actions.editRseBanner(banner)),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType))
})

export default connect(
  null,
  mapDispatchToProps,
)(RseBannersForm)
