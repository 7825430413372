import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
// @material-ui/core components
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
// core components
import customInputStyle from '../../../assets/jss/components/customInputStyle.jsx'

function CustomInput({ ...props }) {
  const { classes, formControlProps, labelText, id, labelProps, error, success } = props

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  })
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  })
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  })
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
          selectMenu: {
            width: '100%',
          },
        }}
        underlinestyle={{ borderColor: '#ff0450' }}
        iconstyle={{ fill: '#ff0000' }}
        labelstyle={{ color: '#ff0000' }}
        value={props.value}
        onChange={props.onChange}
        id={id}
        autoWidth={true}
      >
        {props.values}
      </Select>
      {error ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  )
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
}

export default withStyles(customInputStyle)(CustomInput)
