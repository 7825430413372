import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
// core components
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import CustomInput from '../../../components/UI/CustomInput/CustomInput';
import Button from '../../../components/UI/CustomButtons/Button';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
// @material-ui/icons
import placeholder from '../../../assets/images/placeholder.png';

class PromotionsForm extends Component {
  constructor(props) {
    super(props);
    this.editMode = this.props.location.state.editMode;

    this.state = {
      promotion: this.editMode
        ? { ...this.props.location.state.promotion, file: null }
        : { name: '', description: '', linkUrl: '', file: null }
    };
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newPromotion = { ...this.state.promotion };
    newPromotion[inputIdentifier] = event.target.value;
    this.setState({ promotion: newPromotion });
  };

  fileChangedHandler = (event, inputIdentifier) => {
    const newPromotion = {
      ...this.state.promotion,
      [inputIdentifier]: event.target.files[0]
    };
    this.setState({ promotion: newPromotion });
  };

  handleSubmit = async () => {
    try {
      if (this.editMode) {
        await this.props.onEditPromotion(this.state.promotion);
        this.props.setToast('La promoción fue editada con éxito');
      } else {
        await this.props.onCreatePromotion(this.state.promotion);
        this.props.setToast('La promoción fue creada con éxito');
      }
      this.props.history.push({ pathname: '/promociones' });
    } catch (error) {
      if (this.editMode) {
        this.props.setToast('Se produjo un error al editar la promoción', 'danger');
      } else {
        this.props.setToast('Se produjo un error al crear la promoción', 'danger');
      }
    }
  };

  render() {
    const img = this.state.promotion.file
      ? URL.createObjectURL(this.state.promotion.file)
      : this.editMode
        ? process.env.REACT_APP_BACKEND_BASE_URL + this.state.promotion.imageUrl
        : placeholder;

    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Nombre de la promoción"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.promotion.name,
                    onChange: event => this.inputChangedHandler(event, 'name')
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Descripción"
                  id="description"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.promotion.description,
                    onChange: event =>
                      this.inputChangedHandler(event, 'description')
                  }}
                />
              </GridItem>
            </GridContainer>
            <h2 style={{ margin: '2rem 0' }}>Imagen</h2>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} md={6}>
                <img
                  src={img}
                  alt="Imagen promoción"
                  style={{ width: '350px' }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event, 'file')}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  style={{
                    fontSize: '1rem',
                    margin: '1rem 0'
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Link / Url"
                  id="linkUrl"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.promotion.linkUrl,
                    onChange: event =>
                      this.inputChangedHandler(event, 'linkUrl')
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              style={{ margin: '0px auto 25px auto' }}
              disabled={
                this.state.promotion.name === '' ||
                this.state.promotion.img === null
              }
              onClick={this.handleSubmit}
            >
              {this.editMode ? 'ACTUALIZAR' : 'CREAR'} PROMOCIÓN
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCreatePromotion: promotion => dispatch(actions.createPromotion(promotion)),
  onEditPromotion: promotion => dispatch(actions.editPromotion(promotion)),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType))
});

export default connect(
  null,
  mapDispatchToProps
)(PromotionsForm);
