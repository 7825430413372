import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchBannersStart = () => {
  return {
    type: actionTypes.FETCH_BANNERS_START
  };
};

const fetchBannersSuccess = banners => {
  return {
    type: actionTypes.FETCH_BANNERS_SUCCESS,
    payload: banners
  };
};

const fetchBannersFailed = error => {
  return {
    type: actionTypes.FETCH_BANNERS_FAILED,
    error: error
  };
};

export const fetchBanners = () => async dispatch => {
  dispatch(fetchBannersStart());
  try {
    const { data } = await axios.get('/banners');
    dispatch(fetchBannersSuccess(data.data));
  } catch (err) {
    dispatch(fetchBannersFailed(err));
  }
};

const createBannerFailed = error => {
  return {
    type: actionTypes.CREATE_BANNER_FAILED,
    error: error
  };
};

export const createBanner = banner => async dispatch => {
  try {
    const formData = new FormData();
    for (const key in banner) {
      formData.append(key, banner[key]);
    }
    await axios.post('/banners', formData);
    dispatch(fetchBanners());
  } catch (err) {
    dispatch(createBannerFailed(err));
  }
};

const deleteBannerFailed = err => {
  return {
    type: actionTypes.DELETE_BANNER_FAILED,
    error: err
  };
};

export const deleteBanner = bannerId => async dispatch => {
  try {
    await axios.delete('/banners/' + bannerId);
    dispatch(fetchBanners());
  } catch (err) {
    dispatch(deleteBannerFailed(err));
  }
};

const editBannerFailed = err => {
  return {
    type: actionTypes.EDIT_BANNER_FAILED,
    error: err
  };
};

export const editBanner = banner => async dispatch => {
  try {
    const formData = new FormData();
    for (const key in banner) {
      formData.append(key, banner[key]);
    }
    await axios.put('/banners/' + banner.id, formData);
    dispatch(fetchBanners());
  } catch (err) {
    dispatch(editBannerFailed(err));
  }
};

export const updateOrder = async bannerIds => {
  try {
    await axios.patch('/banners/order', { banners: bannerIds });
  } catch (err) {
    console.log(err);
  }
};
