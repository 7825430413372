import React from 'react'
// react-select
import Select, { components } from 'react-select'
// styles
import classes from './Select.css'

const ControlComponent = props => <components.Control className={classes.CustomSelect} {...props} />

const CustomOption = props => <components.Option className={classes.CustomOption} {...props} />

const Menu = props => (
  <components.Menu {...props} className={classes.CustomMenu}>
    {props.children}
  </components.Menu>
)

const CustomSelect = props => (
  <div className={classes.Wrapper}>
    <Select
      components={{ Control: ControlComponent, Option: CustomOption, Menu: Menu }}
      options={props.options}
      placeholder={props.placeholder}
      value={{ value: props.value, label: props.value }}
      isSearchable={true}
      onChange={props.onChange}
    />
  </div>
)

export default CustomSelect
