import axios from '../../axios'
import * as actionTypes from './actionTypes'

const fetchPromotionsStart = () => {
  return {
    type: actionTypes.FETCH_PROMOTIONS_START,
  }
}

const fetchPromotionsSuccess = promotions => {
  return {
    type: actionTypes.FETCH_PROMOTIONS_SUCCESS,
    payload: promotions,
  }
}

const fetchPromotionsFailed = error => {
  return {
    type: actionTypes.FETCH_PROMOTIONS_FAILED,
    error,
  }
}

export const fetchPromotions = () => async dispatch => {
  dispatch(fetchPromotionsStart())
  try {
    const { data } = await axios.get('/promotions')
    dispatch(fetchPromotionsSuccess(data.data))
  } catch (err) {
    dispatch(fetchPromotionsFailed(err))
  }
}

const createPromotionFailed = error => {
  return {
    type: actionTypes.CREATE_PROMOTION_FAILED,
    error,
  }
}

export const createPromotion = promotion => async dispatch => {
  try {
    const formData = new FormData()
    for (const key in promotion) {
      formData.append(key, promotion[key])
    }
    await axios.post('/promotions', formData)
    dispatch(fetchPromotions())
  } catch (err) {
    dispatch(createPromotionFailed(err))
  }
}

const deletePromotionFailed = error => {
  return {
    type: actionTypes.DELETE_PROMOTION_FAILED,
    error,
  }
}

export const deletePromotion = promotionId => async dispatch => {
  try {
    await axios.delete('/promotions/' + promotionId)
    dispatch(fetchPromotions())
  } catch (err) {
    dispatch(deletePromotionFailed(err))
  }
}

const editPromotionFailed = error => {
  return {
    type: actionTypes.EDIT_PROMOTION_FAILED,
    error,
  }
}

export const editPromotion = promotion => async dispatch => {
  try {
    const formData = new FormData()
    for (const key in promotion) {
      formData.append(key, promotion[key])
    }
    await axios.put('/promotions/' + promotion.id, formData)
    dispatch(fetchPromotions())
  } catch (err) {
    dispatch(editPromotionFailed(err))
  }
}
