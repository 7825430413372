import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import Papa from 'papaparse';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import Button from '../../../components/UI/CustomButtons/Button';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import GridItem from '../../../components/UI/Grid/GridItem';
import Snackbar from '../../../components/UI/Snackbar/Snackbar';
import * as actions from '../../../store/actions';
import classes from './CreateDiscounts.css';

class CreateDiscounts extends Component {
  state = {
    discountsToAdd: [],
  };

  componentDidMount() {
    this.props.onResetDiscountsUploaded();
  }

  fileChangedHandler = event => {
    const csvFile = event.target.files[0];
    Papa.parse(csvFile, {
      complete: (results, file) => {
        this.setState({ discountsToAdd: results.data });
      },
    });
  };

  handleSubmit = () => {
    this.props.onCreateDiscounts(this.state.discountsToAdd);
  };

  render() {
    return (
      <>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event)}
                  accept="csv"
                  multiple={false}
                  className={classes.FileInput}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              disabled={!this.state.discountsToAdd.length > 0}
              onClick={this.handleSubmit}
              className={classes.SubmitButton}
            >
              CREAR DESCUENTOS
            </Button>
          </CardFooter>
        </Card>

        <Snackbar
          place="bl"
          color="success"
          icon={CheckCircleIcon}
          message={`Fueron creados ${this.props.createdDiscountsCount} descuentos con éxito`}
          open={this.props.uploaded}
          closeNotification={this.props.onResetDiscountsUploaded}
          close
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.discounts.loading,
    uploaded: state.discounts.uploaded,
    createdDiscountsCount: state.discounts.createdDiscountsCount,
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateDiscounts: discounts => dispatch(actions.createDiscounts(discounts)),
  onResetDiscountsUploaded: () => dispatch(actions.resetDiscountsUploaded()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateDiscounts);
