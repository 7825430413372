import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
// components
import AddButton from '../../components/UI/CustomButtons/AddButton'
import TableList from '../../components/UI/TableList/TableList'
import Spinner from '../../components/UI/Spinner/Spinner'
import Pagination from '../../components/UI/Pagination/Pagination'
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog'

const PAGE_LIMIT = 12

class Promotions extends Component {
  state = {
    currentPage: 1,
    promotionToDelete: null,
  }

  componentDidMount() {
    this.props.onFetchPromotions()
  }

  handlePageClick = ({ currentPage }) => this.setState({ currentPage })

  handleShowDetail = (promotionId) => {
    const promotion = this.props.promotions.find(p => p.id === promotionId)
    this.props.history.push({
      pathname: `/promociones/${promotionId}`,
      state: {
        promotion,
      },
    })
  }

  handleCreatePromotion = () => {
    this.props.history.push({
      pathname: '/promociones/crear',
      state: {
        editMode: false,
      },
    })
  }

  handleEditPromotion = promotionId => {
    const promotion = this.props.promotions.find(p => p.id === promotionId)
    this.props.history.push({
      pathname: '/promociones/' + promotionId + '/editar',
      state: {
        promotion,
        editMode: true,
      },
    })
  }

  handleDeletePromotion = promotionId => {
    this.setState({ promotionToDelete: promotionId })
  }

  deletePromotion = () => {
    this.props.onDeletePromotion(this.state.promotionToDelete)
    this.setState({ promotionToDelete: null })
  }

  handleCloseDialog = () => {
    this.setState({ promotionToDelete: null })
  }

  render() {
    let promotions = null
    if (this.props.promotions) {
      const options = this.props.promotions
        .slice(
          (this.state.currentPage - 1) * PAGE_LIMIT,
          this.state.currentPage * PAGE_LIMIT,
        )
        .map(promotion => {
          return [
            promotion['id'],
            promotion['name'],
            promotion['description']
          ]
        })

      promotions = (
        <TableList
          title="Promociones"
          head={[
            'Id',
            'Nombre de la promoción',
            'Descripción',
            '',
          ]}
          onShowDetail={this.handleShowDetail}
          onEdit={this.handleEditPromotion}
          onDelete={this.handleDeletePromotion}
          detail
          edit
          delete
          data={options}
          pagination={
            <Pagination
              totalRecords={this.props.promotions.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={10}
              onPageChanged={this.handlePageClick}
            />
          }
        />
      )
    } else if (this.props.loading) {
      promotions = <Spinner />
    }

    return (
      <Fragment>
        {promotions}
        <AddButton onClick={this.handleCreatePromotion} />

        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar esta promoción?'}
          open={this.state.promotionToDelete != null}
          onConfirmation={this.deletePromotion}
          handleClose={this.handleCloseDialog}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    promotions: state.promotions.promotions,
    loading: state.promotions.loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onFetchPromotions: () => dispatch(actions.fetchPromotions()),
  onDeletePromotion: promotionId => dispatch(actions.deletePromotion(promotionId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Promotions)
