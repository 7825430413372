import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import Papa from 'papaparse';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import Button from '../../../components/UI/CustomButtons/Button';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import GridItem from '../../../components/UI/Grid/GridItem';
import Snackbar from '../../../components/UI/Snackbar/Snackbar';
import * as branchOfficesActions from '../../../store/actions/index';
import classes from './CreateBranchOffice.css';

class CreateBranchOfficeCsv extends Component {
  state = {
    brachOfficesToAdd: [],
  };

  fileChangedHandler = event => {
    const csvFile = event.target.files[0];
    Papa.parse(csvFile, {
      complete: (results, file) => {
        this.setState({ brachOfficesToAdd: results.data });
      },
    });
  };

  handleSubmit = () => {
    this.props.onCreateBranchOfficesCsv(this.state.brachOfficesToAdd);
  };

  render() {
    return (
      <>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event)}
                  accept="csv"
                  multiple={false}
                  className={classes.FileInput}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              disabled={!this.state.brachOfficesToAdd.length > 0}
              onClick={this.handleSubmit}
              className={classes.SubmitButton}
            >
              CREAR PUNTOS DE ATENCIÓN
            </Button>
          </CardFooter>
        </Card>

        <Snackbar
          place="bl"
          color="success"
          icon={CheckCircleIcon}
          message={`Fueron creados ${this.props.createdAgilePurchasesCount} puntos de atención con éxito`}
          open={this.props.uploaded}
          close
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateBranchOfficesCsv: branchOffices =>
    dispatch(branchOfficesActions.createBranchOfficesCsv(branchOffices)),
});

export default connect(
  null,
  mapDispatchToProps,
)(CreateBranchOfficeCsv);
