// import Person from '@material-ui/icons/Person'
import Store from '@material-ui/icons/Store'
import {
  Folder,
  Collections,
  LocalActivity,
  Group,
  CreditCard,
  HelpOutline,
  Settings as SettingsIcon,
  BurstMode
} from '@material-ui/icons';
// import Users from '../containers/Users/Users'
import BranchOffices from '../containers/BranchOffices/BranchOffices'
// import BranchOfficeDetail from '../containers/BranchOffices/BranchOfficeDetail/BranchOfficeDetail'
// import CreateBranchOffice from '../containers/BranchOffices/CreateBranchOffice/CreateBranchOffice'
import CreateBranchOfficeCsv from '../containers/BranchOffices/CreateBranchOffice/CreateBranchOfficeCsv'
import Documentation from '../containers/Documentation/Documentation';
import CreateDocument from '../containers/Documentation/CreateDocument/CreateDocument';
import Banners from '../containers/Banners/Banners';
import CreateBanner from '../containers/Banners/CreateBanner/CreateBanner';
import Insurances from '../containers/Insurances/Insurances';
import CreateInsurance from '../containers/Insurances/CreateInsurance/CreateInsurance';
import Promotions from '../containers/Promotions/Promotions';
import PromotionsForm from '../containers/Promotions/PromotionsForm/PromotionsForm';
import PromotionsDetail from '../containers/Promotions/PromotionsDetail/PromotionsDetail';
import Rse from '../containers/Rse/Rse';
import RseBannersForm from '../containers/Rse/RseBannersForm/RseBannersForm';
import RseBannersDetail from '../containers/Rse/RseBannersDetail/RseBannersDetail';
import Settings from '../containers/Settings/Settings';
import Discounts from '../containers/Discounts/Discounts';
import CreateDiscounts from '../containers/Discounts/CreateDiscounts/CreateDiscounts';
import AgilePurchases from '../containers/AgilePurchases/AgilePurchases';
import CreateAgilePurchases from '../containers/AgilePurchases/CreateAgilePurchases/CreateAgilePurchases';
import Faqs from '../containers/Faqs/Faqs';
import CreateFaq from '../containers/Faqs/CreateFaq/CreateFaq';
import Characteristics from '../containers/Characteristics/Characteristics';
import CharacteristicsForm from '../containers/Characteristics/CharacteristicsForm/CharacteristicsForm';
import CharacteristicsDetail from '../containers/Characteristics/CharacteristicsDetail/CharacteristicsDetail';
import BankImages from '../containers/BankImages/BankImages';
import BankImageForm from '../containers/BankImages/BankImageForm/BankImageForm';
import BankImageDetail from '../containers/BankImages/BankImageDetail/BankImageDetail';

const routes = [
  // {
  //   path: '/puntos-de-atencion/crear',
  //   component: CreateBranchOffice,
  // },
  {
    path: '/puntos-de-atencion/crearConCsv',
    component: CreateBranchOfficeCsv,
  },
  // {
  //   path: '/puntos-de-atencion/:id/editar',
  //   component: CreateBranchOffice,
  // },
  // {
  //   path: '/puntos-de-atencion/:id',
  //   component: BranchOfficeDetail,
  // },
  {
    path: '/puntos-de-atencion',
    sidebarName: 'Puntos de atención',
    navbarName: 'Puntos de atención',
    icon: Store,
    component: BranchOffices,
  },
  // {
  //   path: '/usuarios',
  //   sidebarName: 'Usuarios',
  //   navbarName: 'Usuarios',
  //   icon: Person,
  //   component: Users,
  // },
  {
    path: '/banners/crear',
    component: CreateBanner
  },
  {
    path: '/banners/:id/editar',
    component: CreateBanner
  },
  {
    path: '/banners',
    sidebarName: 'Banners',
    navbarName: 'Banners',
    icon: Collections,
    component: Banners
  },
  {
    path: '/seguros/crear',
    component: CreateInsurance
  },
  {
    path: '/seguros/:id/editar',
    component: CreateInsurance
  },
  {
    path: '/seguros',
    sidebarName: 'Seguros',
    navbarName: 'Seguros',
    icon: Collections,
    component: Insurances
  },
  {
    path: '/documentos/crear',
    component: CreateDocument
  },
  {
    path: '/documentos/:id/editar',
    component: CreateDocument
  },
  {
    path: '/faqs/:id/editar',
    component: CreateFaq
  },
  {
    path: '/faqs/crear',
    component: CreateFaq
  },
  {
    path: '/documentos',
    sidebarName: 'Documentos',
    navbarName: 'Documentación',
    icon: Folder,
    component: Documentation
  },
  {
    path: '/promociones/crear',
    component: PromotionsForm
  },
  {
    path: '/promociones/:id/editar',
    component: PromotionsForm
  },
  {
    path: '/promociones/:id',
    component: PromotionsDetail
  },
  {
    path: '/promociones',
    sidebarName: 'Promociones',
    navbarName: 'Promociones',
    icon: LocalActivity,
    component: Promotions
  },
  {
    path: '/descuentos/crear',
    component: CreateDiscounts
  },
  {
    path: '/descuentos',
    sidebarName: 'Descuentos',
    navbarName: 'Descuentos',
    icon: LocalActivity,
    component: Discounts
  },
  {
    path: '/compra-agil/crear',
    component: CreateAgilePurchases
  },
  {
    path: '/compra-agil',
    sidebarName: 'Compra Ágil',
    navbarName: 'Compra Ágil',
    icon: LocalActivity,
    component: AgilePurchases
  },
  {
    path: '/rse/banners/crear',
    component: RseBannersForm
  },
  {
    path: '/rse/banners/:id/editar',
    component: RseBannersForm
  },
  {
    path: '/rse/banners/:id',
    component: RseBannersDetail
  },
  {
    path: '/rse',
    sidebarName: 'RSE',
    navbarName: 'RSE',
    icon: Group,
    component: Rse
  },
  {
    path: '/caracteristica-tarjeta',
    sidebarName: 'Características Tarjeta',
    navbarName: 'Características Tarjeta',
    icon: CreditCard,
    component: Characteristics
  },
  {
    path: '/caracteristicas-tarjeta/crear',
    component: CharacteristicsForm
  },
  {
    path: '/caracteristicas-tarjeta/:id/editar',
    component: CharacteristicsForm
  },
  {
    path: '/caracteristicas-tarjeta/:id',
    component: CharacteristicsDetail
  },
  {
    path: '/banco-de-imagenes/crear',
    component: BankImageForm
  },
  {
    path: '/banco-de-imagenes/:id/editar',
    component: BankImageForm
  },
  {
    path: '/banco-de-imagenes/:id',
    component: BankImageDetail
  },
  {
    path: '/banco-de-imagenes',
    sidebarName: 'Banco de imágenes',
    navbarName: 'Banco de imágenes',
    icon: BurstMode,
    component: BankImages
  },

  {
    path: '/faqs',
    sidebarName: 'FAQs Tarjetas',
    navbarName: 'FAQs Tarjetas',
    icon: HelpOutline,
    component: Faqs
  },
  {
    path: '/configuracion',
    sidebarName: 'Configuración',
    navbarName: 'Configuración',
    icon: SettingsIcon,
    component: Settings
  }
];

export default routes;
