import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import Spinner from '../../components/UI/Spinner/Spinner';
import TableList from '../../components/UI/TableList/TableList';

class Characteristics extends Component {
  state = {
    characteristicToDelete: null,
  };

  componentDidMount() {
    this.props.onFetchCharacteristics();
  }

  handlePageClick = ({ currentPage }) => this.setState({ currentPage });

  handleShowDetail = characteristicId => {
    const characteristic = this.props.characteristics.find(p => p.id === characteristicId);
    this.props.history.push({
      pathname: `/caracteristicas-tarjeta/${characteristicId}`,
      state: {
        characteristic,
      },
    });
  };

  handleCreateCharacteristic = () => {
    this.props.history.push({
      pathname: '/caracteristicas-tarjeta/crear',
      state: {
        editMode: false,
      },
    });
  };

  handleEditCharacteristic = characteristicId => {
    const characteristic = this.props.characteristics.find(p => p.id === characteristicId);
    this.props.history.push({
      pathname: `/caracteristicas-tarjeta/${characteristicId}/editar`,
      state: {
        characteristic,
        editMode: true,
      },
    });
  };

  handleDeleteCharacteristic = characteristicId => {
    this.setState({ characteristicToDelete: characteristicId });
  };

  deleteCharacteristic = () => {
    this.props.onDeleteCharacteristic(this.state.characteristicToDelete);
    this.setState({ characteristicToDelete: null });
  };

  handleCloseDialog = () => {
    this.setState({ characteristicToDelete: null });
  };

  render() {
    let characteristics = null;
    if (this.props.characteristics) {
      const options = this.props.characteristics.map(characteristic => {
        return [characteristic['id'], characteristic['title'], characteristic['description']];
      });

      characteristics = (
        <TableList
          title="Características"
          head={['Id', 'Titulo de la característica', 'Descripción', '']}
          onShowDetail={this.handleShowDetail}
          onEdit={this.handleEditCharacteristic}
          onDelete={this.handleDeleteCharacteristic}
          detail
          edit
          delete
          data={options}
        />
      );
    } else if (this.props.loading) {
      characteristics = <Spinner />;
    }

    return (
      <Fragment>
        {characteristics}
        <AddButton onClick={this.handleCreateCharacteristic} />

        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar esta característica?'}
          open={this.state.characteristicToDelete != null}
          onConfirmation={this.deleteCharacteristic}
          handleClose={this.handleCloseDialog}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    characteristics: state.characteristics.characteristics,
    loading: state.characteristics.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchCharacteristics: () => dispatch(actions.fetchCharacteristics()),
  onDeleteCharacteristic: characteristicId => dispatch(actions.deleteCharacteristic(characteristicId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Characteristics);
