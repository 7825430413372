import React, { Component } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import DOMPurify from 'dompurify';
import { convertFromRaw } from 'draft-js';

const WithTextEditorHandler = (WrappedComponent) => {

  return class extends Component {

    sanitize = (strings, ...values) => {
      return DOMPurify.sanitize(strings);
    }

    convertToHTML = (rawText) => {
      let text = JSON.parse(rawText);
      let fromRaw = convertFromRaw(text);
      return this.sanitize(stateToHTML(fromRaw));
    }

    render() {
      return (
        <WrappedComponent
          convertToHTML={this.convertToHTML}
          {...this.props} />
      );
    }
  }
}

export default WithTextEditorHandler;