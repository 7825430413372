import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import EditableTitleSection from '../../components/UI/EditableTitleSection/EditableTitleSection';
import Spinner from '../../components/UI/Spinner/Spinner';
import TableList from '../../components/UI/TableList/TableList';
import TextEditorDialog from '../../components/UI/TextEditorDialog/TextEditorDialog';
import withTextEditor from '../../hoc/withTextEditor/withTextEditor';
import * as actions from '../../store/actions/index';

class Rse extends Component {
  state = {
    showEditTextDialog: false,
  };

  componentDidMount() {
    this.props.onFetchRse();
  }

  handleShowDetails = bannerId => {
    let banner = this.props.rse.banners.find(banner => banner._id === bannerId);
    this.props.history.push({
      pathname: '/rse/banners/' + bannerId,
      state: {
        banner,
      },
    });
  };

  handleCreateBanner = () => {
    this.props.history.push({
      pathname: '/rse/banners/crear',
      state: {
        editMode: false,
      },
    });
  };

  handleEditBanner = bannerId => {
    const banner = this.props.rse.banners.find(b => b._id === bannerId);
    this.props.history.push({
      pathname: '/rse/banners/' + bannerId + '/editar',
      state: {
        banner,
        editMode: true,
      },
    });
  };

  handleDeleteBanner = bannerId => {
    this.setState({ bannerToDelete: bannerId });
  };

  deleteBanner = () => {
    this.props.onDeleteBanner(this.state.bannerToDelete);
    this.setState({ bannerToDelete: null });
  };

  handleDeleteCancelled = () => {
    this.setState({ bannerToDelete: null });
  };

  handleTextEdited = textJson => {
    this.props.onEditRseText(textJson);

    this.setEditTextDialogVisibility(false);
  };

  setEditTextDialogVisibility = visible => {
    this.setState({ showEditTextDialog: visible });
  };

  render() {
    let rseTextHTML = null;
    const { convertToHTML } = this.props;
    if (this.props.rse && this.props.rse.text) {
      rseTextHTML = convertToHTML(this.props.rse.text);
    }

    let bannersList = null;

    if (this.props.rse && this.props.rse.banners) {
      const banners = this.props.rse.banners.map(banner => {
        return [banner['_id'], banner['name'], banner['description']];
      });

      bannersList = (
        <TableList
          title="RSE Banners"
          head={['Id', 'Nombre', 'Descripción', '']}
          detail
          edit
          delete
          onShowDetail={this.handleShowDetails}
          onEdit={this.handleEditBanner}
          onDelete={this.handleDeleteBanner}
          data={banners}
        />
      );
    } else if (this.props.loading) {
      bannersList = <Spinner />;
    }

    return (
      <>
        <EditableTitleSection
          title="Información RSE"
          html={rseTextHTML}
          onClick={() => this.setEditTextDialogVisibility(true)}
        />

        {this.props.rse ? (
          <TextEditorDialog
            title="Editar Información"
            raw={this.props.rse && this.props.rse.text ? this.props.rse.text : ''}
            open={this.state.showEditTextDialog}
            onClose={() => this.setEditTextDialogVisibility(false)}
            onSave={this.handleTextEdited}
          />
        ) : null}

        {bannersList}

        <AddButton onClick={this.handleCreateBanner} />

        <CustomDialog
          title="Confirmar"
          description="¿Estás seguro que deseas eliminar este banner?"
          open={this.state.bannerToDelete != null}
          onConfirmation={this.deleteBanner}
          handleClose={this.handleDeleteCancelled}
          okButtonText="Eliminar"
          cancelButtonText="Cancelar"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    rse: state.rse.rse,
    error: state.rse.error,
    loading: state.rse.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchRse: () => dispatch(actions.fetchRse()),
    onEditRseText: text => dispatch(actions.editRseText(text)),
    onDeleteBanner: id => dispatch(actions.deleteRseBanner(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTextEditor(Rse));
