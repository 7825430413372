import React, { Component } from 'react'
import Card from '../../../components/UI/Card/Card'
import CardBody from '../../../components/UI/Card/CardBody'
// styles
import classes from './RseBannersDetail.css'

class RseBannersDetail extends Component {

  render() {
    const { banner } = this.props.location.state

    return (
      <Card>
        <CardBody className={classes.CardBody}>
          <h1 className={classes.Title}>{banner.name}</h1>
          {banner.description && (
            <div>
              <h3>Descripción</h3>
              <p>{banner.description}</p>
            </div>
          )}
          {banner.linkUrl && (
            <div>
              <h3>Link</h3>
              <p>{banner.linkUrl}</p>
            </div>
          )}
          {banner.imageUrl && (
            <img
              src={process.env.REACT_APP_BACKEND_BASE_URL + banner.imageUrl}
              alt="Imagen RSE banner"
              style={{ width: '350px' }}
            />
          )}
        </CardBody>
      </Card>
    )
  }
}

export default RseBannersDetail