import * as actionTypes from '../actions/actionTypes';

const initialState = {
  bankImages: null,
  loading: false,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_BANK_IMAGES_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_BANK_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        bankImages: action.payload
      };
    case actionTypes.FETCH_BANK_IMAGES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
