import { Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import Pagination from '../../components/UI/Pagination/Pagination';
import Spinner from '../../components/UI/Spinner/Spinner';
import TableList from '../../components/UI/TableList/TableList';
import * as actions from '../../store/actions';
import classes from './AgilePurchases.css';

const PAGE_LIMIT = 12;

class AgilePurchases extends Component {
  state = {
    currentPage: 1,
    toDelete: [],
    showModal: false,
  };

  componentDidMount() {
    this.props.onFetchAgilePurchases();
  }

  handleCreateAgilePurchases = () => {
    this.props.history.push({
      pathname: '/compra-agil/crear',
    });
  };

  handleElementClick = element => this.setState({ toDelete: element });

  handleOpenDialog = () => this.setState({ showModal: true });

  handleCloseDialog = () => this.setState({ showModal: false });

  handleDeleteMany = async () => {
    try {
      await this.props.onDeleteAgilePurchases(this.state.toDelete);
      this.props.setToast(`Se eliminaron ${this.state.toDelete.length} comercios`);
      this.setState({ toDelete: [] });
    } catch (error) {
      this.props.setToast(
        'Ha ocurrido un error, no se pudieron eliminar los comercios seleccionados.',
        'danger',
      );
    }
    this.setState({ showModal: false });
  };

  handlePageClick = ({ currentPage }) => this.setState({ currentPage: currentPage });

  render() {
    let agilePurchases = null;
    if (this.props.agilePurchases) {
      const options = this.props.agilePurchases
        .slice((this.state.currentPage - 1) * PAGE_LIMIT, this.state.currentPage * PAGE_LIMIT)
        .map(agilePurchase => {
          return [
            agilePurchase['_id'],
            agilePurchase['department'],
            agilePurchase['zone'],
            agilePurchase['businessName'],
            agilePurchase['businessType'],
            agilePurchase['hasDiscount'] ? 'Sí' : 'No',
            agilePurchase['discount'] ? String(agilePurchase['discount']) : '',
            agilePurchase['legal'],
          ];
        });

      agilePurchases = (
        <TableList
          title="Comercios Compra Ágil"
          head={[
            'Id',
            'Departamento',
            'Zona',
            'Comercio',
            'Rubro',
            'Tiene descuento',
            'Descuento',
            'Legal',
            '',
          ]}
          data={options}
          selectable
          showSelectAll
          selected={this.state.toDelete}
          onSelect={this.handleElementClick}
          pagination={
            <Pagination
              totalRecords={this.props.agilePurchases.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={10}
              onPageChanged={this.handlePageClick}
            />
          }
          headerActions={
            <Button
              onClick={this.handleOpenDialog}
              className={classes.Button}
              disabled={this.props.loading || this.state.toDelete.length === 0}
            >
              <Delete />
            </Button>
          }
        />
      );
    } else if (this.props.loading) {
      agilePurchases = <Spinner />;
    }

    return (
      <>
        {agilePurchases}
        <AddButton onClick={this.handleCreateAgilePurchases} />
        <CustomDialog
          title={`¿Estás seguro que deseas eliminar ${this.state.toDelete.length} comercios?`}
          open={this.state.showModal}
          onConfirmation={this.handleDeleteMany}
          handleClose={this.handleCloseDialog}
          okButtonText="Eliminar"
          cancelButtonText="Cancelar"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    agilePurchases: state.agilePurchases.agilePurchases,
    loading: state.agilePurchases.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchAgilePurchases: () => dispatch(actions.fetchAgilePurchases()),
  onDeleteAgilePurchases: agilePurchases => dispatch(actions.deleteAgilePurchases(agilePurchases)),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgilePurchases);
