import React, { Component } from "react";
import Card from "../../../components/UI/Card/Card";
import CardBody from "../../../components/UI/Card/CardBody";
import classes from "./BankImageDetail.css";

class BankImageDetail extends Component {
  render() {
    const { bankImage } = this.props.location.state;
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }${bankImage.imageUrl.replaceAll(String.fromCharCode(92), "/")}`;

    return (
      <Card>
        <CardBody className={classes.CardBody}>
          <h1 className={classes.Title}>Nombre: {bankImage.name}</h1>
          <>
            <h3>Clasificación:</h3>
            <p>{bankImage.classification}</p>
          </>
          {bankImage.description && (
            <>
              <h3>Descripción:</h3>
              <p>{bankImage.description}</p>
            </>
          )}
          <>
            <h3>URL:</h3>
            <p>{url}</p>
          </>
          <img
            src={`${process.env.REACT_APP_BACKEND_BASE_URL}${
              bankImage.imageUrl
            }`}
            alt={`${bankImage.name} imagen`}
            className={classes.ImgStyle}
          />
        </CardBody>
      </Card>
    );
  }
}

export default BankImageDetail;
