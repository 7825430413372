import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchFaqsStart = () => {
  return {
    type: actionTypes.FETCH_FAQS_START
  };
};

const fetchFaqsSuccess = faqs => {
  return {
    type: actionTypes.FETCH_FAQS_SUCCESS,
    payload: faqs
  };
};

const fetchFaqsFailed = error => {
  return {
    type: actionTypes.FETCH_FAQS_FAILED,
    error: error
  };
};

export const fetchFaqs = () => async dispatch => {
  dispatch(fetchFaqsStart());
  try {
    const { data } = await axios.get('/faqs');
    dispatch(fetchFaqsSuccess(data.data));
  } catch (err) {
    dispatch(fetchFaqsFailed(err));
  }
};

const createFaqFailed = error => {
  return {
    type: actionTypes.CREATE_FAQ_FAILED,
    error: error
  };
};

export const createFaq = faq => async dispatch => {
  try {
    await axios.post('/faqs', faq);
    dispatch(fetchFaqs());
  } catch (err) {
    dispatch(createFaqFailed(err));
  }
};

const deleteFaqFailed = err => {
  return {
    type: actionTypes.DELETE_FAQ_FAILED,
    error: err
  };
};

export const deleteFaq = faqId => async dispatch => {
  try {
    await axios.delete(`/faqs/${faqId}`);
    dispatch(fetchFaqs());
  } catch (err) {
    dispatch(deleteFaqFailed(err));
  }
};

const editFaqFailed = err => {
  return {
    type: actionTypes.EDIT_FAQ_FAILED,
    error: err
  };
};

export const editFaq = faq => async dispatch => {
  try {
    await axios.patch(`/faqs/${faq._id}`, faq);
    dispatch(fetchFaqs());
  } catch (err) {
    dispatch(editFaqFailed(err));
  }
};