import React from 'react'
// material ui
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import withStyles from '@material-ui/core/styles/withStyles'

import buttonStyle from '../../../assets/jss/components/buttonStyle.jsx'

const addButton = props => {
  const { classes } = props
  return (
    <Button
      variant="fab"
      color="primary"
      aria-label="Add"
      className={classes.addButton}
      onClick={props.onClick}
    >
      <AddIcon />
    </Button>
  )
}

export default withStyles(buttonStyle)(addButton)
