import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as documentsActions from '../../../store/actions/index'
// @material-ui/core components
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from '../../../components/UI/Grid/GridItem'
import GridContainer from '../../../components/UI/Grid/GridContainer'
import CustomInput from '../../../components/UI/CustomInput/CustomInput'
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect'
import Button from '../../../components/UI/CustomButtons/Button'
import Card from '../../../components/UI/Card/Card'
import CardBody from '../../../components/UI/Card/CardBody'
import CardFooter from '../../../components/UI/Card/CardFooter'
import Snackbar from '../../../components/UI/Snackbar/Snackbar'
// @material-ui/icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
// utils
import {
  documentType,
  convertDocumentTypeToString,
} from '../../../enums/documentType'

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
  },
}

class CreateDocument extends Component {
  constructor(props) {
    super(props)
    this.editMode = this.props.location.state.editMode

    this.state = {
      document: this.editMode
        ? { ...this.props.location.state.document }
        : { title: '', group: '', file: null },
      snackbar: false,
    }
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newDocument = { ...this.state.document }
    newDocument[inputIdentifier] = event.target.value
    this.setState({ document: newDocument })
  }

  fileChangedHandler = event => {
    const newDocument = { ...this.state.document, file: event.target.files[0] }
    this.setState({ document: newDocument })
  }

  handleGroupChange = event => {
    const newDocument = {
      ...this.state.document,
      group: event.target.value,
    }
    this.setState({ document: newDocument })
  }

  showSuccessSnackbar = () => {
    this.setState({ snackbar: true })
  }

  render() {
    const { classes } = this.props
    const groups = Object.keys(documentType).map(docType => {
      return (
        <MenuItem
          style={{ width: '200px' }}
          key={documentType[docType]}
          value={documentType[docType]}
        >
          {convertDocumentTypeToString(documentType[docType])}
        </MenuItem>
      )
    })

    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <CustomInput
                  labelText="Título"
                  id="title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.document.title,
                    onChange: event => this.inputChangedHandler(event, 'title'),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <FormControl className={classes.formControl}>
                  <CustomSelect
                    labelText="Grupo"
                    id="group"
                    value={this.state.document.group}
                    values={groups}
                    onChange={this.handleGroupChange}
                    inputProps={{
                      name: 'group',
                      id: 'group',
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <input
                  type="file"
                  onChange={this.fileChangedHandler}
                  accept="application/pdf"
                  multiple={false}
                  style={{
                    fontSize: '1rem',
                    margin: '1rem 0',
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              style={{ margin: '0px auto 25px auto' }}
              disabled={
                this.state.document.title === '' ||
                this.state.document.group === '' ||
                this.state.document.file === null
              }
              onClick={() =>
                this.editMode
                  ? this.props.onEditDocument(this.state.document).then(() => {
                      this.showSuccessSnackbar()
                    })
                  : this.props
                      .onCreateDocument(this.state.document)
                      .then(() => {
                        this.showSuccessSnackbar()
                      })
              }
            >
              {this.editMode ? 'ACTUALIZAR DOCUMENTO' : 'CREAR DOCUMENTO'}
            </Button>
          </CardFooter>
        </Card>

        <Snackbar
          place="bl"
          color="success"
          icon={CheckCircleIcon}
          message={
            'El documento fue ' +
            (this.editMode ? 'editado' : 'creado') +
            ' con éxito'
          }
          open={this.state.snackbar}
          closeNotification={() => this.setState({ snackbar: false })}
          close
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateDocument: document =>
    dispatch(
      documentsActions.createDocument(
        document.title,
        document.group,
        document.file,
      ),
    ),
  onEditDocument: document => dispatch(documentsActions.editDocument(document)),
})

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(CreateDocument))
