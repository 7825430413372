import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import axios from '../../axios';
import {
  AUTH_START,
  SET_CURRENT_USER,
  AUTH_ERROR,
  LOGOUT
} from './actionTypes';

export const loadUser = () => async dispatch => {
  // Check for a token
  if (localStorage.token) {
    // Set token in axios header
    setAuthHeader(localStorage.token);
    try {
      // Check if token is valid, then dispatch user
      const res = await axios.get('/users/me');
      dispatch({ type: SET_CURRENT_USER, payload: res.data.data });
    } catch (err) {
      // If is not valid, remove token from localStorage
      localStorage.removeItem('token');
      // Delete token from axios header
      setAuthHeader(null);
      dispatch({ type: AUTH_ERROR });
    }
  } else {
    dispatch({ type: AUTH_ERROR });
  }
};

export const login = formData => async dispatch => {
  dispatch({ type: AUTH_START });

  try {
    const hashDigest = sha256(formData.password).toString();
    const hmacDigest = Base64.stringify(
      hmacSHA512(hashDigest, process.env.REACT_APP_KEY)
    );
    const res = await axios.post('/users/login', {
      ...formData,
      password: hmacDigest
    });

    // Set token to localStorage
    localStorage.setItem('token', res.data.data.token);
    // Get user data
    dispatch(loadUser());
  } catch (err) {
    let errorMessage =
      'Ha ocurrido un error al iniciar sesión. Por favor vuelva a intentarlo más tarde.';
    if (err.response && err.response.data) {
      errorMessage = err.response.data.showMessage;
    }
    dispatch({ type: AUTH_ERROR, payload: errorMessage });
  }
};

export const logout = () => async dispatch => {
  // Remove session token in backend
  try {
    await axios.post('/users/logout', {});
    dispatch({ type: LOGOUT });
  } catch (err) {
    dispatch({ type: LOGOUT });
  }

  // Remove token from localStorage
  localStorage.removeItem('token');
  // Remove header from axios
  setAuthHeader(null);
};

export const setAuthHeader = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};
