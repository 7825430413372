import React, { Component } from 'react'
import Card from '../../../components/UI/Card/Card'
import CardBody from '../../../components/UI/Card/CardBody'
// styles
import classes from './PromotionsDetail.css'

class PromotionsDetail extends Component {

  render() {
    const { promotion } = this.props.location.state

    return (
      <Card>
        <CardBody className={classes.CardBody}>
          <h1 className={classes.Title}>{promotion.name}</h1>
          {promotion.description && (
            <div>
              <h3>Descripción</h3>
              <p>{promotion.description}</p>
            </div>
          )}
          {promotion.linkUrl && (
            <div>
              <h3>Link</h3>
              <p>{promotion.linkUrl}</p>
            </div>
          )}
          {promotion.imageUrl && (
            <img
              src={process.env.REACT_APP_BACKEND_BASE_URL + promotion.imageUrl}
              alt="Imagen promoción"
              style={{ width: '350px' }}
            />
          )}
        </CardBody>
      </Card>
    )
  }
}

export default PromotionsDetail