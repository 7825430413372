import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
// core components
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import CustomInput from '../../../components/UI/CustomInput/CustomInput';
import Button from '../../../components/UI/CustomButtons/Button';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
// @material-ui/icons
import placeholder from '../../../assets/images/placeholder.png';

class CreateBanner extends Component {
  constructor(props) {
    super(props);
    this.editMode = this.props.location.state.editMode;

    this.state = {
      banner: this.editMode
        ? { ...this.props.location.state.banner, desktop: null, mobile: null }
        : { name: '', linkUrl: '', desktop: null, mobile: null }
    };
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newBanner = { ...this.state.banner };
    newBanner[inputIdentifier] = event.target.value;
    this.setState({ banner: newBanner });
  };

  fileChangedHandler = (event, inputIdentifier) => {
    const newBanner = {
      ...this.state.banner,
      [inputIdentifier]: event.target.files[0]
    };
    this.setState({ banner: newBanner });
  };

  handleSubmit = async () => {
    try {
      if (this.editMode) {
        await this.props.onEditBanner(this.state.banner);
        this.props.setToast('El banner fue editado con éxito');
      } else {
        await this.props.onCreateBanner(this.state.banner);
        this.props.setToast('El banner fue creado con éxito');
      }
      this.props.history.push({ pathname: '/banners' })
    } catch (error) {
      if (this.editMode) {
        this.props.setToast('Se produjo un error al editar el banner', 'danger');
      } else {
        this.props.setToast('Se produjo un error al crear el banner', 'danger');
      }
    }
  };

  render() {
    const desktopImg = this.state.banner.desktop
      ? URL.createObjectURL(this.state.banner.desktop)
      : this.editMode
        ? process.env.REACT_APP_BACKEND_BASE_URL + this.state.banner.imageUrl
        : placeholder;

    const mobileImg = this.state.banner.mobile
      ? URL.createObjectURL(this.state.banner.mobile)
      : this.editMode
        ? process.env.REACT_APP_BACKEND_BASE_URL +
          this.state.banner.imageResponsiveUrl
        : placeholder;

    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Nombre del banner"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.banner.name,
                    onChange: event => this.inputChangedHandler(event, 'name')
                  }}
                />
              </GridItem>
            </GridContainer>
            <h2 style={{ margin: '2rem 0' }}>Banner para Desktop</h2>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} md={6}>
                <img
                  src={desktopImg}
                  alt="Desktop banner"
                  style={{ width: '350px' }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event, 'desktop')}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  style={{
                    fontSize: '1rem',
                    margin: '1rem 0'
                  }}
                />
              </GridItem>
            </GridContainer>
            <h2 style={{ margin: '2rem 0' }}>Banner para Mobile</h2>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} md={6}>
                <img
                  src={mobileImg}
                  alt="Mobile banner"
                  style={{ width: '350px' }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event, 'mobile')}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  style={{
                    fontSize: '1rem',
                    margin: '1rem 0'
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Link / Url (opcional)"
                  id="linkUrl"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.banner.linkUrl,
                    onChange: event =>
                      this.inputChangedHandler(event, 'linkUrl')
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              style={{ margin: '0px auto 25px auto' }}
              disabled={
                this.state.banner.name === '' ||
                this.state.banner.imageDesktop === null ||
                this.state.banner.imageMobile === null
              }
              onClick={this.handleSubmit}
            >
              {this.editMode ? 'ACTUALIZAR' : 'CREAR'} BANNER
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateBanner: banner => dispatch(actions.createBanner(banner)),
  onEditBanner: banner => dispatch(actions.editBanner(banner)),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateBanner);
