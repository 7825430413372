import * as actionTypes from '../actions/actionTypes'

const initialState = {
  promotions: null,
  tourismOffers: null,
  loading: false,
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {

    case actionTypes.FETCH_RSE_SUCCESS:
    return {
      ...state,
      rse: action.rse,
      error: false,
      loading: false
    }
  case actionTypes.FETCH_RSE_FAILED:
    return {
      ...state,
      error: true,
      loading: false
    };
    default:
      return state
  }
}