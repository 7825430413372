import React, { Component } from 'react';
import classNames from 'classnames';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
// @material-ui/core components
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// @material-ui/icons
import { Edit, Close, Description, DragHandle } from '@material-ui/icons';
// styles
import tableStyle from '../../../assets/jss/components/tableStyle';

const SortableItem = SortableElement(
  ({ classes, data, onShowDetail, onEdit, onDelete }) => (
    <TableRow>
      {data.map((value, index) => {
        if (index === 1) {
          return (
            <TableCell key={index} className={classes.tableCell}>
              <Tooltip
                id="tooltip-top"
                title="Arrastrar para ordenar"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
                style={{ verticalAlign: 'middle' }}
              >
                <DragHandle
                  className={classNames(
                    classes.tableActionButtonIcon,
                    classes.edit,
                    classes.handle
                  )}
                />
              </Tooltip>
              <span style={{ marginLeft: '10px', verticalAlign: 'middle' }}>
                {value}
              </span>
            </TableCell>
          );
        } else if (index !== 0) {
          return (
            <TableCell key={index} className={classes.tableCell}>
              {value.startsWith('http') ? (
                <a href={value} target="_blank" rel="noopener noreferrer">
                  Link para visualizar
                </a>
              ) : (
                value
              )}
            </TableCell>
          );
        }
        return null;
      })}
      {onShowDetail || onEdit || onDelete ? (
        <TableCell className={classes.tableActions}>
          {onShowDetail ? (
            <Tooltip
              id="tooltip-top"
              title="Detalle"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Detalle"
                className={classes.tableActionButton}
                onClick={onShowDetail.bind(this, data[0])}
              >
                <Description
                  className={classNames(
                    classes.tableActionButtonIcon,
                    classes.edit
                  )}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {onEdit ? (
            <Tooltip
              id="tooltip-top"
              title="Editar"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Editar"
                className={classes.tableActionButton}
                onClick={onEdit.bind(this, data[0])}
              >
                <Edit
                  className={classNames(
                    classes.tableActionButtonIcon,
                    classes.edit
                  )}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {onDelete ? (
            <Tooltip
              id="tooltip-top-start"
              title="Eliminar"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Eliminar"
                className={classes.tableActionButton}
                onClick={onDelete.bind(this, data[0])}
              >
                <Close
                  className={classNames(
                    classes.tableActionButtonIcon,
                    classes.close
                  )}
                />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
      ) : null}
    </TableRow>
  )
);

const SortableTableBody = SortableContainer(
  ({ items, classes, onShowDetail, onEdit, onDelete }) => (
    <TableBody>
      {items.map((item, index) => (
        <SortableItem
          key={index}
          index={index}
          data={item}
          classes={classes}
          onShowDetail={onShowDetail}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </TableBody>
  )
);

class TableList extends Component {
  state = {
    items: this.props.tableData
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.setState({ items: arrayMove(this.state.items, oldIndex, newIndex) });
      this.props.onSaveSort(this.state.items.map(item => item[0]));
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          <TableHead className={classes.grayTableHeader}>
            <TableRow>
              {this.props.tableHead.map((value, index) => {
                if (value !== 'Id') {
                  return (
                    <TableCell
                      key={index}
                      className={classNames(
                        classes.tableCell,
                        classes.tableHeadCell
                      )}
                    >
                      {value}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <SortableTableBody
            axis="y"
            lockAxis="y"
            distance={1}
            classes={classes}
            items={this.state.items}
            onShowDetail={this.props.onShowDetail}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            onSortEnd={this.onSortEnd}
          />
        </Table>
      </div>
    );
  }
}

export default withStyles(tableStyle)(TableList);
