import * as actionTypes from '../actions/actionTypes'

const initialState = {
  documents: null,
}

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      }
    case actionTypes.GET_DOCUMENTS:
      return {
        ...state,
      }
    case actionTypes.FETCH_DOCUMENTS_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }
    default:
      return state
  }
}

export default documentsReducer
