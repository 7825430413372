import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchCharacteristicsStart = () => {
  return {
    type: actionTypes.FETCH_CHARACTERISTICS_START
  };
};

const fetchCharacteristicsSuccess = characteristic => {
  return {
    type: actionTypes.FETCH_CHARACTERISTICS_SUCCESS,
    payload: characteristic
  };
};

const fetchCharacteristicsFailed = error => {
  return {
    type: actionTypes.FETCH_CHARACTERISTICS_FAILED,
    error
  };
};

export const fetchCharacteristics = () => async dispatch => {
  dispatch(fetchCharacteristicsStart());
  try {
    const { data } = await axios.get('/characteristics');
    dispatch(fetchCharacteristicsSuccess(data.data));
  } catch (err) {
    dispatch(fetchCharacteristicsFailed(err));
  }
};

const createCharacteristicFailed = error => {
  return {
    type: actionTypes.CREATE_CHARACTERISTIC_FAILED,
    error
  };
};

export const createCharacteristic = characteristic => async dispatch => {
  try {
    const formData = new FormData();
    for (const key in characteristic) {
      formData.append(key, characteristic[key]);
    }
    await axios.post('/characteristics', formData);
    dispatch(fetchCharacteristics());
  } catch (err) {
    dispatch(createCharacteristicFailed(err));
  }
};

const deleteCharacteristicFailed = error => {
  return {
    type: actionTypes.DELETE_CHARACTERISTIC_FAILED,
    error
  };
};

export const deleteCharacteristic = characteristicId => async dispatch => {
  try {
    await axios.delete(`/characteristics/${characteristicId}`);
    dispatch(fetchCharacteristics());
  } catch (err) {
    dispatch(deleteCharacteristicFailed(err));
  }
};

const editCharacteristicFailed = error => {
  return {
    type: actionTypes.EDIT_CHARACTERISTIC_FAILED,
    error
  };
};

export const editCharacteristic = characteristic => async dispatch => {
  try {
    const formData = new FormData();
    for (const key in characteristic) {
      formData.append(key, characteristic[key]);
    }
    await axios.put(`/characteristics/${characteristic.id}`, formData);
    dispatch(fetchCharacteristics());
  } catch (err) {
    dispatch(editCharacteristicFailed(err));
  }
};