import * as actionTypes from '../actions/actionTypes'

const initialState = {
  agilePurchases: null,
  uploaded: false,
  loading: false,
  createdAgilePurchasesCount: 0,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_AGILE_PURCHASES_START:
      return {
        ...state,
        loading: true,
        uploaded: false,
        error: null,
      }
    case actionTypes.FETCH_AGILE_PURCHASES_SUCCESS:
      return {
        ...state,
        loading: false,
        agilePurchases: action.payload,
      }
    case actionTypes.FETCH_AGILE_PURCHASES_FAILED:
      return {
        ...state,
        loading: false,
        uploaded: false,
        error: action.error,
      }
    case actionTypes.CREATE_AGILE_PURCHASES_SUCCESS:
      return {
        ...state,
        loading: false,
        uploaded: true,
        createdAgilePurchasesCount: action.createdAgilePurchasesCount,
        error: null,
      }
    case actionTypes.CREATE_AGILE_PURCHASES_FAILED:
      return {
        ...state,
        loading: false,
        uploaded: false,
        createdAgilePurchasesCount: action.createdAgilePurchasesCount,
        error: action.error,
      }
    case actionTypes.RESET_AGILE_PURCHASES_UPLOADED:
      return {
        ...state,
        uploaded: false,
        createdAgilePurchasesCount: 0,
      }
      case actionTypes.DELETE_AGILE_PURCHASES_START:
        return {
          ...state,
          loading: true,
          error: null,
        }
      case actionTypes.DELETE_AGILE_PURCHASES_SUCCESS:
        return {
          ...state,
          loading: false,
        }
      case actionTypes.DELETE_AGILE_PURCHASES_FAILED:
        return {
          ...state,
          loading: false,
          error: action.error,
        }
    default:
      return state
  }
}
