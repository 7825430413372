import withStyles from '@material-ui/core/styles/withStyles';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import branchOfficesStyle from '../../assets/jss/containers/listsContainer';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import GridContainer from '../../components/UI/Grid/GridContainer';
import GridItem from '../../components/UI/Grid/GridItem';
import Pagination from '../../components/UI/Pagination/Pagination';
import SpecialInput from '../../components/UI/SpecialInput/Input';
import TableList from '../../components/UI/TableList/TableList';
import { departments } from '../../departments';
import * as branchOfficesActions from '../../store/actions/index';

const PAGE_LIMIT = 12;

class BranchOffices extends Component {
  state = {
    department: 'Todos',
    zone: 'Todos',
    name: '',
    currentPage: 1,
    branchOfficeToDelete: null,
    zones: ['Todos'],
  };

  componentDidMount() {
    this.props.onGetBranchOffices(null, null);
  }

  handleDepartmentChange = value => this.setState({ department: value });

  handleNameChange = value => this.setState({ name: value });

  handlePageClick = ({ currentPage }) => this.setState({ currentPage: currentPage });

  handleCreateBranchOffice = () => {
    this.props.history.push({ pathname: '/puntos-de-atencion/crearConCsv' });
  };

  handleDeleteBranchOffice = branchOfficeId => {
    this.setState({ branchOfficeToDelete: branchOfficeId });
  };

  deleteBranchOffice = () => {
    this.props.deleteBranchOffice(this.state.branchOfficeToDelete);
    this.setState({ branchOfficeToDelete: null });
  };

  handleDeleteCancelled = () => {
    this.setState({ branchOfficeToDelete: null });
  };

  render() {
    const departmentOptions = departments.map(department => ({
      value: department,
      label: department,
    }));

    const filters = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <SpecialInput
            element={{
              label: 'Buscar..',
              elementType: 'input',
              elementConfig: { type: 'text', placeholder: 'Buscar..' },
              value: this.state.name,
              validation: {},
            }}
            onChange={this.handleNameChange}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <SpecialInput
            element={{
              label: 'Departamento',
              elementType: 'select',
              elementConfig: { variant: 'outlined', options: departmentOptions, mdUpSize: 6 },
              value: this.state.department,
            }}
            onChange={this.handleDepartmentChange}
          />
        </GridItem>
      </GridContainer>
    );

    let options = null;
    let branchOfficesToShow = null;
    if (this.props.branchOffices) {
      branchOfficesToShow = this.props.branchOffices;

      if (this.state.name !== '') {
        branchOfficesToShow = branchOfficesToShow.filter(branch => {
          return ['name'].some(key => {
            return (
              branch[key] &&
              branch[key].toLowerCase().includes(this.state.name && this.state.name.toLowerCase())
            );
          });
        });
      }

      if (this.state.department !== 'Todos') {
        branchOfficesToShow = branchOfficesToShow.filter(branch => {
          return branch.department === this.state.department;
        });
      }

      options = branchOfficesToShow
        .slice((this.state.currentPage - 1) * PAGE_LIMIT, this.state.currentPage * PAGE_LIMIT)
        .map(branchOffice => {
          return [
            branchOffice['_id'],
            branchOffice['mainBranch'] ? 'Primario' : 'Secundario',
            branchOffice['name'],
            branchOffice['address'],
            branchOffice['department'],
            branchOffice['phone'],
            branchOffice['schedule'],
          ];
        });
    }

    return (
      <Fragment>
        {branchOfficesToShow && (
          <TableList
            title="Puntos de atención"
            filters={filters}
            head={['Id', 'Tipo', 'Nombre', 'Dirección', 'Departamento', 'Teléfono', 'Horarios', '']}
            onDelete={this.handleDeleteBranchOffice}
            delete
            data={options}
            pagination={
              <Pagination
                totalRecords={branchOfficesToShow.length}
                pageLimit={PAGE_LIMIT}
                pageNeighbours={10}
                onPageChanged={this.handlePageClick}
              />
            }
          />
        )}
        <AddButton onClick={this.handleCreateBranchOffice} />

        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar este Punto de atención?'}
          open={this.state.branchOfficeToDelete != null}
          onConfirmation={this.deleteBranchOffice}
          handleClose={this.handleDeleteCancelled}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  branchOffices: state.branchOffices.branchOffices,
  error: state.branchOffices.error,
  loading: state.branchOffices.loading,
  description: state.branchOffices.description,
  zones: state.branchOffices.zones,
});

const mapDispatchToProps = dispatch => ({
  onGetBranchOffices: (department, zone, name) =>
    dispatch(branchOfficesActions.getBranchOffices(department, zone, name)),
  deleteBranchOffice: id => dispatch(branchOfficesActions.deleteBranchOffice(id)),
  onGetZonesByDepartment: department => dispatch(branchOfficesActions.getZonesByDepartment(department)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(branchOfficesStyle)(BranchOffices));
