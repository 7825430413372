export const documentType = {
  cash: 1,
  agilePurchase: 2,
  insurance: 3,
  basesAndConditions: 4,
  goodPracticeCode: 5
};

export function convertDocumentTypeToString(docType) {
  switch (docType) {
    case documentType['cash']:
      return 'Efectivo';
    case documentType['agilePurchase']:
      return 'Compra Ágil';
    case documentType['insurance']:
      return 'Seguros';
    case documentType['goodPracticeCode']:
      return 'Código de Buenas Prácticas';
    case documentType['basesAndConditions']:
      return 'Bases y Condiciones';
    default:
      return '';
  }
}
