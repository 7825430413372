import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import Spinner from '../../components/UI/Spinner/Spinner';
import TableList from '../../components/UI/TableList/TableList';
import { parseDate } from '../../shared/utility';
import * as actions from '../../store/actions';

class Discounts extends Component {
  componentDidMount() {
    this.props.onFetchDiscounts();
  }

  handleCreateDiscounts = () => {
    this.props.history.push({
      pathname: '/descuentos/crear',
    });
  };

  render() {
    let discounts = null;
    if (this.props.discounts) {
      const options = this.props.discounts.map(discount => {
        return [
          discount['_id'],
          discount['description'],
          discount['department'],
          discount['zone'],
          discount['businessName'],
          discount['businessType'],
          discount['installments'],
          parseDate(discount['validFrom']),
          parseDate(discount['validUntil']),
          discount['legal'],
        ];
      });

      discounts = (
        <TableList
          title="Descuentos"
          head={[
            'Id',
            'Descripción',
            'Departamento',
            'Zona',
            'Comercio',
            'Rubro',
            'Cuotas',
            'Vigencia desde',
            'Vigencia hasta',
            'Legal',
            '',
          ]}
          data={options}
        />
      );
    } else if (this.props.loading) {
      discounts = <Spinner />;
    }

    return (
      <>
        {discounts}
        <AddButton onClick={this.handleCreateDiscounts} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    discounts: state.discounts.discounts,
    loading: state.discounts.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchDiscounts: () => dispatch(actions.fetchDiscounts()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Discounts);
