import React, { Component } from 'react';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import classes from './CharacteristicsDetail.css';

class CharacteristicsDetail extends Component {
  render() {
    const { characteristic } = this.props.location.state;

    return (
      <Card>
        <CardBody className={classes.CardBody}>
          <h1 className={classes.Title}>{characteristic.title}</h1>
          {characteristic.description && (
            <div>
              <h3>Descripción</h3>
              <p>{characteristic.description}</p>
            </div>
          )}
          {characteristic.imageUrl && (
            <img
              src={`${process.env.REACT_APP_BACKEND_BASE_URL}${
                characteristic.imageUrl
              }`}
              alt="Imagen característica"
              className={classes.ImgStyle}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

export default CharacteristicsDetail;
