/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent, Fragment } from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// material ui
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
// styles
import classes from './Pagination.css'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'
const FIRST_PAGE = 'FIRST'
const LAST_PAGE = 'LAST'

const pageLimitDefault = 20

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

class Pagination extends PureComponent {
  constructor(props) {
    super(props)
    const { totalRecords = null, pageLimit = pageLimitDefault, pageNeighbours = 0 } = props

    this.state = {
      pageLimit: typeof pageLimit === 'number' ? pageLimit : pageLimitDefault,
      totalRecords: typeof totalRecords === 'number' ? totalRecords : 0,
      pageNeighbours:
        typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 3)) : 0,
      totalPages: Math.ceil(totalRecords / pageLimit),
      currentPage: 1,
    }
  }

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const totalPages = this.state.totalPages
    const currentPage = this.state.currentPage
    const pageNeighbours = this.state.pageNeighbours

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.state.pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(1, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages, currentPage + pageNeighbours)

      let pages = range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 1
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      // handle: (1) < {5 6} [7] {8 9} (10)
      if (hasLeftSpill && !hasRightSpill) {
        const extraPages = range(startPage - spillOffset, startPage - 1)
        pages = [LEFT_PAGE, ...extraPages, ...pages]
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      else if (!hasLeftSpill && hasRightSpill) {
        const extraPages = range(endPage + 1, endPage + spillOffset)
        pages = [...pages, ...extraPages, RIGHT_PAGE]
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      else if (hasLeftSpill && hasRightSpill) {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
      }

      if (hasLeftSpill) {
        pages = [FIRST_PAGE, ...pages]
      }

      if (hasRightSpill) {
        pages = [...pages, LAST_PAGE]
      }

      return pages
    }
    return range(1, totalPages)
  }

  componentDidMount() {
    this.gotoPage(1)
  }

  static getDerivedStateFromProps(props, currentState) {
    if (currentState.totalRecords !== props.totalRecords) {
      return {
        totalRecords: props.totalRecords,
        totalPages: Math.ceil(props.totalRecords / props.pageLimit),
        currentPage: 1,
      }
    }
    return null
  }

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props

    const currentPage = Math.max(0, Math.min(page, this.state.totalPages))

    const paginationData = {
      currentPage,
      totalPages: this.state.totalPages,
      pageLimit: this.state.pageLimit,
      totalRecords: this.state.totalRecords,
    }

    this.setState({ currentPage }, () => onPageChanged(paginationData))
  }

  handleClick = page => evt => {
    evt.preventDefault()
    this.gotoPage(page)
  }

  handleMoveLeft = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage - this.state.pageNeighbours * 2 - 1)
  }

  handleMoveRight = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage + this.state.pageNeighbours * 2 + 1)
  }

  handleMoveFirst = evt => {
    evt.preventDefault()
    this.gotoPage(1)
  }

  handleMoveLast = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.totalPages)
  }

  render() {
    if (!this.state.totalRecords || this.state.totalPages === 1) return null

    const { currentPage } = this.state
    const pages = this.fetchPageNumbers()

    return (
      <Fragment>
        <ul className={classes.Pagination}>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className={classes.PageItem}>
                  <a
                    className={classes.PageLink}
                    href="#"
                    aria-label="Previous"
                    onClick={this.handleMoveLeft}
                  >
                    <ChevronLeft className={classes.ChevronIcon} />
                  </a>
                </li>
              )

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className={classes.PageItem}>
                  <a
                    className={classes.PageLink}
                    href="#"
                    aria-label="Next"
                    onClick={this.handleMoveRight}
                  >
                    <ChevronRight className={classes.ChevronIcon} />
                  </a>
                </li>
              )

            if (page === FIRST_PAGE)
              return (
                <li key={index} className={classes.PageItem}>
                  <a
                    className={classes.PageLink}
                    href="#"
                    aria-label="First"
                    onClick={this.handleMoveFirst}
                  >
                    <FirstPage className={classes.ChevronIcon} />
                  </a>
                </li>
              )

            if (page === LAST_PAGE)
              return (
                <li key={index} className={classes.PageItem}>
                  <a
                    className={classes.PageLink}
                    href="#"
                    aria-label="First"
                    onClick={this.handleMoveLast}
                  >
                    <LastPage className={classes.ChevronIcon} />
                  </a>
                </li>
              )

            return (
              <li
                key={index}
                className={
                  currentPage === page
                    ? [classes.Active, classes.PageItem].join(' ')
                    : classes.PageItem
                }
              >
                <a className={classes.PageLink} href="#" onClick={this.handleClick(page)}>
                  {page}
                </a>
              </li>
            )
          })}
        </ul>
      </Fragment>
    )
  }
}

Pagination.propTypes = {
  // total number of records
  totalRecords: PropTypes.number.isRequired,
  // records per page
  pageLimit: PropTypes.number,
  // number of additional page numbers to show on each side of the current page
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
}

export default Pagination
