import React from 'react';
// material ui core components
import CircularProgress from '@material-ui/core/CircularProgress';
// styles
import classes from './Spinner.css';

const spinner = props => {
  return (
    <CircularProgress
      color="primary"
      className={classes.Spinner}
      style={{
        color: props.color,
        width: `${props.size}px`,
        height: `${props.size}px`
      }}
    />
  );
};

export default spinner;
