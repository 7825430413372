import { FormControl, MenuItem } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import placeholder from "../../../assets/images/placeholder.png";
import Card from "../../../components/UI/Card/Card";
import CardBody from "../../../components/UI/Card/CardBody";
import CardFooter from "../../../components/UI/Card/CardFooter";
import Button from "../../../components/UI/CustomButtons/Button";
import CustomInput from "../../../components/UI/CustomInput/CustomInput";
import CustomSelect from "../../../components/UI/CustomSelect/CustomSelect";
import GridContainer from "../../../components/UI/Grid/GridContainer";
import GridItem from "../../../components/UI/Grid/GridItem";
import * as actions from "../../../store/actions";
import classes from "./CharacteristicsForm.css";

class CharacteristicsForm extends Component {
  constructor(props) {
    super(props);
    this.editMode = this.props.location.state.editMode || false;

    this.state = {
      characteristic: this.props.location.state.editMode
        ? { ...this.props.location.state.characteristic }
        : {
            title: "",
            description: "",
            actionType: "",
            actionLink:
              this.state !== undefined &&
              this.state.characteristic.actionType === "image"
                ? null
                : "",
            imageUrl: null
          },
      youtubeInputValidation: null
    };
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newCharacteristic = { ...this.state.characteristic };
    if (inputIdentifier === "actionType") {
      newCharacteristic.actionLink = inputIdentifier === "image" ? null : "";
    }
    newCharacteristic[inputIdentifier] = event.target.value;
    this.setState({ characteristic: newCharacteristic });
  };

  fileChangedHandler = (event, inputIdentifier) => {
    const newCharacteristic = {
      ...this.state.characteristic,
      [inputIdentifier]: event.target.files[0]
    };
    this.setState({ characteristic: newCharacteristic });
  };

  handleSelectActionType = e => this.inputChangedHandler(e, "actionType");

  handleSubmit = async () => {
    try {
      if (this.editMode) {
        await this.props.onEditCharacteristic(this.state.characteristic);
        this.props.setToast("La característica fue editada con éxito");
      } else {
        await this.props.onCreateCharacteristic(this.state.characteristic);
        this.props.setToast("La característica fue creada con éxito");
      }
      this.props.history.push({ pathname: "/caracteristica-tarjeta" });
    } catch (error) {
      if (this.editMode) {
        this.props.setToast(
          "Se produjo un error al editar la característica",
          "danger"
        );
      } else {
        this.props.setToast(
          "Se produjo un error al crear la característica",
          "danger"
        );
      }
    }
  };

  render() {
    const actionType = this.state.characteristic.actionType;

    const buttonIsDisabled =
      this.state.characteristic.title === "" ||
      this.state.characteristic.img === null ||
      actionType === null ||
      (this.state.characteristic.actionLink === null &&
        this.state.characteristic.modalImage === null);

    const actionTypesOptions = [
      { value: "redirect", label: "Link de redirección" },
      { value: "video", label: "Video" },
      { value: "image", label: "Imagen" }
    ];

    const BannerImage =
      this.state.characteristic.imageUrl &&
      typeof this.state.characteristic.imageUrl !== "string"
        ? URL.createObjectURL(this.state.characteristic.imageUrl)
        : this.editMode
          ? `${process.env.REACT_APP_BACKEND_BASE_URL}${
              this.state.characteristic.imageUrl
            }`
          : placeholder;

    const ModalImage =
      typeof this.state.characteristic.actionLink !== "string" &&
      actionType === "image"
        ? URL.createObjectURL(this.state.characteristic.actionLink)
        : this.editMode
          ? `${process.env.REACT_APP_BACKEND_BASE_URL}${
              this.state.characteristic.actionLink
            }`
          : placeholder;

    const actionTypesOptionsValues = actionTypesOptions.map(option => (
      <MenuItem
        style={{ width: "200px" }}
        key={option.value}
        value={option.value}
      >
        {option.label}
      </MenuItem>
    ));

    const validateYoutubeUrl = youtubeUrl => {
      const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const match = youtubeUrl.match(regExp);

      return match;
    };

    const handleChangeYoutubeUrl = e => {
      const isYoutubeUrl = validateYoutubeUrl(e.target.value);

      if (isYoutubeUrl) {
        this.inputChangedHandler(e, "actionLink");
      } else {
        this.setState({
          youtubeInputValidation: "La URL no tiene el formato correcto."
        });
        this.props.setToast("El link ingresado no es correcto", "danger");
      }
    };

    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Título de la característica"
                  id="title"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.characteristic.title,
                    onChange: event => this.inputChangedHandler(event, "title")
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Descripción"
                  id="description"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.characteristic.description,
                    onChange: event =>
                      this.inputChangedHandler(event, "description")
                  }}
                />
              </GridItem>
            </GridContainer>
            <h2 className={classes.H2Image}>Imagen</h2>
            <p>*La imagen proporcionada debe ser cuadrada</p>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} md={6}>
                <img
                  className={classes.Image}
                  src={BannerImage}
                  alt="Imagen característica"
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event, "imageUrl")}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  className={classes.InputImage}
                  required
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <FormControl className={classes.FormControl}>
                  <CustomSelect
                    labelText="Acción"
                    id="actionType"
                    value={actionType}
                    values={actionTypesOptionsValues}
                    onChange={event =>
                      this.inputChangedHandler(event, "actionType")
                    }
                    inputProps={{ name: "actionType", id: "actionType" }}
                    formControlProps={{ fullWidth: true }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={8}>
                {actionType === "video" ? (
                  <>
                    <h2>Link a video de Youtube</h2>
                    <p>Ejemplo: https://www.youtube.com/watch?v=k9fNskqyQdw</p>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Link"
                        id="actionLink"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          value: this.state.characteristic.actionLink,
                          onChange: handleChangeYoutubeUrl
                        }}
                        error={this.state.youtubeInputValidation}
                      />
                    </GridItem>
                  </>
                ) : actionType === "image" ? (
                  <>
                    <h2 className={classes.H2Image}>
                      Imagen que aparecerá en el modal
                    </h2>
                    <GridContainer alignItems="center" justify="center">
                      <GridItem xs={12}>
                        <img
                          className={classes.Image}
                          src={ModalImage}
                          alt="Imagen de modal"
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <input
                          type="file"
                          onChange={event =>
                            this.fileChangedHandler(event, "actionLink")
                          }
                          accept="image/jpeg, image/png"
                          multiple={false}
                          className={classes.InputImage}
                          required
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                ) : (
                  actionType === "redirect" && (
                    <>
                      <h2>Link a página de destino</h2>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText="Link"
                          id="actionLink"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            value: this.state.characteristic.actionLink,
                            onChange: event =>
                              this.inputChangedHandler(event, "actionLink")
                          }}
                        />
                      </GridItem>
                    </>
                  )
                )}
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className={classes.SubmitButton}
              disabled={buttonIsDisabled}
              onClick={this.handleSubmit}
            >
              {this.editMode ? "ACTUALIZAR" : "CREAR"} CATEGORÍA
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateCharacteristic: characteristic =>
    dispatch(actions.createCharacteristic(characteristic)),
  onEditCharacteristic: characteristic =>
    dispatch(actions.editCharacteristic(characteristic)),
  setToast: (message, messageType) =>
    dispatch(actions.setToast(message, messageType))
});

export default connect(
  null,
  mapDispatchToProps
)(CharacteristicsForm);
