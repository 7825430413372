import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { SortableTable, Table } from "../Table";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
    },
    "& a,& a:hover,& a:focus": { color: "#FFFFFF" }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

const tableList = props => {
  const { classes } = props;

  return (
    <GridContainer>
      {props.filters ? <GridItem xs={12}>{props.filters}</GridItem> : null}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.tableHeader} >
              <h4 className={classes.cardTitleWhite}>{props.title}</h4>
              {props.headerActions}
            </div>
          </CardHeader>
          <CardBody>
            {props.sortable ? (
              <SortableTable
                tableHeaderColor="primary"
                tableHead={props.head}
                tableData={props.data}
                detail={props.detail}
                edit={props.edit}
                delete={props.delete}
                onShowDetail={props.onShowDetail}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                onSaveSort={props.onSaveSort}
              />
            ) : (
              <Table
                showURL={props.showURL}
                handleUrlClick={props.handleUrlClick}
                tableHeaderColor="primary"
                tableHead={props.head}
                tableData={props.data}
                detail={props.detail}
                edit={props.edit}
                delete={props.delete}
                onShowDetail={props.onShowDetail}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                showSelectAll={props.showSelectAll}
                selectableIndex={props.selectableIndex}
                selectable={props.selectable}
                selected={props.selected}
                onSelect={props.onSelect}
                clickable={props.clickable}
                onCellClick={props.onCellClick}
              />
            )}
            {props.pagination}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withRouter(withStyles(styles)(tableList));
