import React, { Component } from 'react';
import { Editor, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
// material components
import { Button } from '@material-ui/core';
// material icons
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  Code,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote
} from '@material-ui/icons';
// styles
import myClasses from './TextEditor.css';

const styles = {
  BOLD: <FormatBold />,
  ITALIC: <FormatItalic />,
  UNDERLINE: <FormatUnderlined />,
  CODE: <Code />
};

const blockTypes = {
  'header-one': <p>H1</p>,
  'header-two': <p>H2</p>,
  'header-three': <p>H3</p>,
  'header-four': <p>H4</p>,
  'header-five': <p>H5</p>,
  'header-six': <p>H6</p>,
  'unordered-list-item': <FormatListBulleted />,
  'ordered-list-item': <FormatListNumbered />,
  blockquote: <FormatQuote />
};

class MyEditor extends Component {
  onButtonClick = style => {
    const newState = RichUtils.toggleInlineStyle(this.state.editorState, style);
    this.props.onChange(newState);
  };

  onBlockTypeClick = blockType => {
    const newState = RichUtils.toggleBlockType(
      this.state.editorState,
      blockType
    );
    this.props.onChange(newState);
  };

  render() {
    const buttons = Object.keys(styles).map(style => (
      <Button
        key={style}
        name={style}
        className={myClasses.ToolbarButton}
        onClick={() => this.onButtonClick(style)}
      >
        {styles[style]}
      </Button>
    ));

    const blockTypeButtons = Object.keys(blockTypes).map(blockType => (
      <Button
        key={blockType}
        name={blockType}
        className={myClasses.ToolbarButton}
        onClick={() => this.onBlockTypeClick(blockType)}
      >
        {blockTypes[blockType]}
      </Button>
    ));

    return (
      <div className={myClasses.TextEditor}>
        <div className={myClasses.Toolbar}>
          {buttons}
          {blockTypeButtons}
        </div>
        <Editor
          editorState={this.props.editorState}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default MyEditor;
