import * as actionTypes from './actionTypes'
import axios from '../../axios'

const branchOfficesBaseRoute = '/branch-offices'

export const getBranchOfficesDescription = () => dispatch => {
  axios
    .get(branchOfficesBaseRoute + '/desc')
    .then(({ data: { data } }) => {
      dispatch(setBranchOfficesDescription(data))
    })
    .catch(err => {
      dispatch(fetchBranchOfficesDescriptionFailed())
    })
}

const setBranchOfficesDescription = desc => ({
  type: actionTypes.SET_BRANCH_OFFICES_DESCRIPTION,
  description: desc,
})

const fetchBranchOfficesDescriptionFailed = () => ({
  type: actionTypes.FETCH_BRANCH_OFFICES_DESCRIPTION_FAILED,
})

export const editBranchOfficesDescription = desc => dispatch =>
  new Promise((resolve, reject) => {
    axios
      .put(branchOfficesBaseRoute + '/desc', { desc })
      .then(({ data }) => {
        dispatch(getBranchOfficesDescription())
        resolve(data)
      })
      .catch(err => {
        dispatch(editBranchOfficesDescriptionFailed())
      })
  })

const editBranchOfficesDescriptionFailed = () => ({
  type: actionTypes.EDIT_BRANCH_OFFICES_DESCRIPTION_FAILED,
})

const fetchBranchOfficesStart = () => ({
  type: actionTypes.FETCH_BRANCH_OFFICES_START,
})

export const getBranchOffices = (department, zone, name) => dispatch => {
  dispatch(fetchBranchOfficesStart())
  let route = branchOfficesBaseRoute
  const parameters = {}
  if (department && department !== 'Todos') parameters['department'] = department
  if (zone && zone !== 'Todos') parameters['zone'] = zone

  const esc = encodeURIComponent
  const query = Object.keys(parameters)
    .map(k => esc(k) + '=' + esc(parameters[k]))
    .join('&')
  if (query) route += '?' + query

  axios
    .get(route)
    .then(({ data: { data } }) => {
      // let { branchOffices, total } = extractBranchOfficesData(response)
      dispatch(setBranchOffices(data, data.length))
    })
    .catch(err => {
      dispatch(fetchBranchOfficesFailed())
    })
}

const setBranchOffices = (branchOffices, total) => ({
  type: actionTypes.SET_BRANCH_OFFICES,
  branchOffices: branchOffices,
  totalBranchOffices: total,
})

const fetchBranchOfficesFailed = () => ({
  type: actionTypes.FETCH_BRANCH_OFFICES_FAILED,
})

export const createBranchOffice = branchOffice => dispatch =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    // append logo to be uploaded
    formData.append('logo', branchOffice.logo)
    // remove logo from object
    delete branchOffice.logo
    // append entire object to be parsed
    formData.append('branchOffice', JSON.stringify(branchOffice))
    axios
      .post(branchOfficesBaseRoute, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => {
        dispatch(getBranchOffices(null, null))
        resolve(data)
      })
      .catch(err => {
        dispatch(createBranchOfficeFailed())
      })
  })

const createBranchOfficeFailed = () => ({
  type: actionTypes.CREATE_BRANCH_OFFICE_FAILED,
})

const createBranchOfficesCsvSuccess = createdBranchOfficesCount => ({
  type: actionTypes.CREATE_BRANCH_OFFICES_CSV_SUCCESS,
  createdBranchOfficesCount
})

const createBranchOfficesCsvFailed = error => ({
  type: actionTypes.CREATE_BRANCH_OFFICES_CSV_FAILED,
  error
})

export const createBranchOfficesCsv = branchOffices => async dispatch => {
  try {
    const { data } = await axios.post('/branch-offices/bulk', {
      branchOffices: branchOffices,
    });
    dispatch(createBranchOfficesCsvSuccess(data.data.createdBranchOfficesCount));
  } catch (err) {
    dispatch(createBranchOfficesCsvFailed(err));
  }
}

export const editBranchOffice = branchOffice => dispatch =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    // append logo only if it's a file, if not, its the same logo
    if (typeof branchOffice.logo !== 'string') {
      formData.append('logo', branchOffice.logo)
    }
    // delete logo from object
    delete branchOffice.logo
    // add entire object to be parsed
    formData.append('branchOffice', JSON.stringify(branchOffice))
    axios
      .put(branchOfficesBaseRoute + '/' + branchOffice._id, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => {
        dispatch(getBranchOffices(null, null))
        resolve(data)
      })
      .catch(err => {
        dispatch(editBranchOfficeFailed())
      })
  })

const editBranchOfficeFailed = () => ({
  type: actionTypes.EDIT_BRANCH_OFFICE_FAILED,
})

export const deleteBranchOffice = id => dispatch => {
  axios
    .delete(branchOfficesBaseRoute + '/' + id)
    .then(() => {
      dispatch(getBranchOffices(null, null))
    })
    .catch(err => {
      dispatch(deleteBranchOfficeFailed())
    })
}

const deleteBranchOfficeFailed = () => ({
  type: actionTypes.DELETE_BRANCH_OFFICE_FAILED,
})

export const getZonesByDepartment = department => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getZones())
    axios
      .get(`${branchOfficesBaseRoute}/zones?department=${department}`)
      .then(({ data: { data } }) => {
        dispatch(setZones(data))
        resolve(data)
      })
      .catch(err => {
        dispatch(fetchZonesFailed(err))
        reject(err)
      })
  })

const getZones = () => ({
  type: actionTypes.GET_ZONES,
})

const setZones = zones => ({
  type: actionTypes.SET_ZONES,
  zones: zones,
})

const fetchZonesFailed = error => ({
  type: actionTypes.FETCH_ZONES_FAILED,
  error: error,
})

// function extractBranchOfficesData(res) {
//   let link = res.headers['link'];
//   let links = link.split(',')
//   let total
//   for (let key of links) {
//     if (key.includes('total')) {
//       total = parseInt(key.split(';')[0], 10)
//     }
//   }
//   return { branchOffices: res.data.data, total: total } || {};
// }
