import {
  AUTH_START,
  AUTH_ERROR,
  SET_CURRENT_USER,
  LOGOUT,
} from "../actions/actionTypes";

const initialState = {
  isAuthenticated: false,
  loading: false,
  loadingUser: true,
  user: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        loadingUser: false,
        loading: false,
        user: action.payload,
        error: null,
      };
    case AUTH_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        loadingUser: false,
        user: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
