import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import {
  Code,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatUnderlined,
} from '@material-ui/icons';
import { Editor, EditorState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import React, { Component } from 'react';
import classes from './TextEditorDialog.css';

const styles = {
  BOLD: <FormatBold />,
  ITALIC: <FormatItalic />,
  UNDERLINE: <FormatUnderlined />,
  CODE: <Code />,
};

const blockTypes = {
  'header-one': <p>H1</p>,
  'header-two': <p>H2</p>,
  'header-three': <p>H3</p>,
  'header-four': <p>H4</p>,
  'header-five': <p>H5</p>,
  'header-six': <p>H6</p>,
  'unordered-list-item': <FormatListBulleted />,
  'ordered-list-item': <FormatListNumbered />,
  blockquote: <FormatQuote />,
};

class MyEditor extends Component {
  constructor(props) {
    super(props);

    if (props.raw) {
      let fromRaw = convertFromRaw(JSON.parse(props.raw));
      this.state = { editorState: EditorState.createWithContent(fromRaw) };
    } else {
      this.state = { editorState: EditorState.createEmpty() };
    }

    this.onChange = editorState => {
      this.setState({ editorState });
      let toRaw = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
      localStorage.setItem('draftRaw', toRaw);
    };
  }

  _onClick = style => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
  };

  _onBlockTypeClick = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  onSave = () => {
    this.props.onSave(localStorage.getItem('draftRaw'));
  };

  render() {
    const buttons = Object.keys(styles).map(style => (
      <Button
        className={classes.ToolbarButton}
        key={style}
        onMouseDown={() => this._onClick(style)}
        name={style}
      >
        {styles[style]}
      </Button>
    ));

    const blockTypeButtons = Object.keys(blockTypes).map(blockType => (
      <Button
        key={blockType}
        className={classes.ToolbarButton}
        onMouseDown={() => this._onBlockTypeClick(blockType)}
        name={blockType}
      >
        {blockTypes[blockType]}
      </Button>
    ));

    return (
      <Dialog
        classes={{
          paper: classes.CustomDialog,
        }}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <div className={classes.TextEditor}>
            <div className={classes.Toolbar}>
              {buttons}
              {blockTypeButtons}
            </div>
            <Editor editorState={this.state.editorState} onChange={this.onChange} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.props.onClose}>
            Cancelar
          </Button>
          <Button onClick={this.onSave} color="primary" autoFocus>
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default MyEditor;
