import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { logout } from './store/actions';
import branchOfficesReducer from './store/reducers/branchOffices';
import documentsReducer from './store/reducers/documents';
import authReducer from './store/reducers/auth';
import bannersReducer from './store/reducers/banners';
import promotionsReducer from './store/reducers/promotions';
import tourismOffersReducer from './store/reducers/tourismOffers';
import rseReducer from './store/reducers/rse';
import insurancesReducer from './store/reducers/insurances';
import discountsReducer from './store/reducers/discounts';
import agilePurchasesReducer from './store/reducers/agilePurchases';
import faqReducer from './store/reducers/faqs';
import characteristicsReducer from './store/reducers/characteristics';
import toastReducer from './store/reducers/toast';
import bankImagesReducer from './store/reducers/bankImages';
import axios from './axios';
import './index.css';

const composeEnhancers =
  (process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  branchOffices: branchOfficesReducer,
  documents: documentsReducer,
  auth: authReducer,
  banners: bannersReducer,
  promotions: promotionsReducer,
  tourismOffers: tourismOffersReducer,
  rse: rseReducer,
  insurances: insurancesReducer,
  discounts: discountsReducer,
  agilePurchases: agilePurchasesReducer,
  faq: faqReducer,
  characteristics: characteristicsReducer,
  toast: toastReducer,
  bankImages: bankImagesReducer
});

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ec1f39'
    },
    secondary: {
      main: '#b9c700'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <MuiThemeProvider theme={muiTheme}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_REF}>
        <App />
      </BrowserRouter>
    </MuiThemeProvider>
  </Provider>
);

// Auth interceptor
axios.interceptors.response.use(
  response => response,
  err => {
    if (err.response.data.errorCode === 103) {
      store.dispatch(logout());
    }
    return Promise.reject(err);
  }
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
