import * as actionTypes from './actionTypes'
import axios from '../../axios'

const documentationBaseRoute = '/documents'

export const getDocuments = (group = null) => dispatch => {
  let route = documentationBaseRoute
  const parameters = {}
  if (group) parameters['group'] = group

  const esc = encodeURIComponent
  const query = Object.keys(parameters)
    .map(k => esc(k) + '=' + esc(parameters[k]))
    .join('&')
  if (query) route += '?' + query

  axios
    .get(route)
    .then(({ data: { data } }) => {
      dispatch(setDocuments(data, data.length))
    })
    .catch(err => {
      dispatch(fetchDocumentsFailed())
    })
}

const setDocuments = (documents, total) => ({
  type: actionTypes.SET_DOCUMENTS,
  documents: documents,
  totalDocuments: total,
})

const fetchDocumentsFailed = () => ({
  type: actionTypes.FETCH_DOCUMENTS_FAILED,
})

export const createDocument = (title, group, file) => dispatch =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('title', title)
    formData.append('group', group)
    formData.append('file', file)
    axios
      .post(documentationBaseRoute, formData)
      .then(({ data }) => {
        dispatch(getDocuments())
        resolve(data)
      })
      .catch(err => {
        dispatch(createDocumentFailed())
      })
  })

const createDocumentFailed = () => ({
  type: actionTypes.CREATE_DOCUMENT_FAILED,
})

export const editDocument = document => dispatch =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    for (const key in document) {
      formData.append(key, document[key])
    }
    axios
      .put(documentationBaseRoute + '/' + document._id, formData)
      .then(({ data }) => {
        dispatch(getDocuments())
        resolve(data)
      })
      .catch(err => {
        dispatch(editDocumentFailed())
      })
  })

const editDocumentFailed = () => ({
  type: actionTypes.EDIT_DOCUMENT_FAILED,
})

export const deleteDocument = id => dispatch => {
  axios
    .delete(documentationBaseRoute + '/' + id)
    .then(() => {
      dispatch(getDocuments())
    })
    .catch(err => {
      dispatch(deleteDocumentFailed())
    })
}

const deleteDocumentFailed = () => ({
  type: actionTypes.DELETE_DOCUMENT_FAILED,
})

// function extractDocumentsData(res) {
//   let link = res.headers['link']
//   let links = link.split(',')
//   let total
//   for (let key of links) {
//     if (key.includes('total')) {
//       total = parseInt(key.split(';')[0], 10)
//     }
//   }
//   console.log(res)
//   return { documents: res.data.data, total: total } || {}
// }
