import React from 'react'
// material ui
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'

import HeaderLinks from './HeaderLinks/HeaderLinks'
import headerStyle from '../../assets/jss/components/headerStyle.jsx'

const header = props => {
  const { classes, color } = props
  const appBarClasses = classNames({
    [' ' + classes[color]]: color,
  })
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex} />
        <Hidden smDown implementation="css">
          <HeaderLinks onLogoutClick={props.handleLogout} />
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(withStyles(headerStyle)(header))
