import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
// components
import AddButton from '../../components/UI/CustomButtons/AddButton';
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';

const PAGE_LIMIT = 12;

class Insurances extends Component {
  state = {
    currentPage: 1,
    insuranceToDelete: null
  };

  componentDidMount() {
    this.props.onFetchInsurances();
  }

  handlePageClick = ({ currentPage }) => this.setState({ currentPage });

  handleCreateInsurance = () => {
    this.props.history.push({
      pathname: '/seguros/crear',
      state: {
        editMode: false
      }
    });
  };

  handleEditInsurance = insuranceId => {
    const insurance = this.props.insurances.find(b => b._id === insuranceId);
    this.props.history.push({
      pathname: '/seguros/' + insuranceId + '/editar',
      state: {
        insurance,
        editMode: true
      }
    });
  };

  handleDeleteInsurance = insuranceId => {
    this.setState({ insuranceToDelete: insuranceId });
  };

  deleteInsurance = () => {
    this.props.onDeleteInsurance(this.state.insuranceToDelete);
    this.setState({ insuranceToDelete: null });
  };

  handleCloseDialog = () => {
    this.setState({ insuranceToDelete: null });
  };

  render() {
    let insurances = null;
    if (this.props.insurances) {
      const options = this.props.insurances
        .slice(
          (this.state.currentPage - 1) * PAGE_LIMIT,
          this.state.currentPage * PAGE_LIMIT
        )
        .map(insurance => {
          return [
            insurance['_id'],
            insurance['name'],
            process.env.REACT_APP_BACKEND_BASE_URL + insurance['frontImageUrl'],
            process.env.REACT_APP_BACKEND_BASE_URL + insurance['backImageUrl']
          ];
        });

      insurances = (
        <TableList
          title="Seguros"
          head={['Id', 'Nombre', 'Imagen de frente', 'Imagen de fondo', '']}
          onEdit={this.handleEditInsurance}
          onDelete={this.handleDeleteInsurance}
          edit
          delete
          data={options}
          pagination={
            <Pagination
              totalRecords={this.props.insurances.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={10}
              onPageChanged={this.handlePageClick}
            />
          }
        />
      );
    } else if (this.props.loading) {
      insurances = <Spinner />;
    }

    return (
      <Fragment>
        {insurances}
        <AddButton onClick={this.handleCreateInsurance} />
        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar este seguro?'}
          open={this.state.insuranceToDelete != null}
          onConfirmation={this.deleteInsurance}
          handleClose={this.handleCloseDialog}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    insurances: state.insurances.insurances,
    loading: state.insurances.loading
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchInsurances: () => dispatch(actions.fetchInsurances()),
  onDeleteInsurance: insuranceId =>
    dispatch(actions.deleteInsurance(insuranceId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Insurances);
