import axios from "../../axios";
import * as actionTypes from "./actionTypes";

const fetchBankImagesStart = () => {
  return {
    type: actionTypes.FETCH_BANK_IMAGES_START
  };
};

const fetchBankImagesSuccess = bankImages => ({
  type: actionTypes.FETCH_BANK_IMAGES_SUCCESS,
  payload: bankImages
});

const fetchBankImagesFailed = error => ({
  type: actionTypes.FETCH_BANK_IMAGES_FAILED,
  error: error
});

export const fetchBankImages = () => async dispatch => {
  dispatch(fetchBankImagesStart());
  try {
    const { data } = await axios.get("/bank-images");
    dispatch(fetchBankImagesSuccess(data.data));
  } catch (err) {
    dispatch(fetchBankImagesFailed(err));
  }
};

const createBankImageFailed = error => ({
  type: actionTypes.CREATE_BANK_IMAGE_FAILED,
  error: error
});

export const createBankImage = bankImage => async dispatch => {
  try {
    const formData = new FormData();
    for (const key in bankImage) {
      formData.append(key, bankImage[key]);
    }
    await axios.post("/bank-images", formData);
    dispatch(fetchBankImages());
  } catch (err) {
    dispatch(createBankImageFailed(err));
    throw err;
  }
};

const deleteBankImageFailed = err => ({
  type: actionTypes.DELETE_BANK_IMAGE_FAILED,
  error: err
});

export const deleteBankImage = bankImageId => async dispatch => {
  try {
    await axios.delete("/bank-images/" + bankImageId);
    dispatch(fetchBankImages());
  } catch (err) {
    dispatch(deleteBankImageFailed(err));
  }
};

const editBankImageFailed = err => ({
  type: actionTypes.EDIT_BANK_IMAGE_FAILED,
  error: err
});

export const editBankImage = bankImage => async dispatch => {
  try {
    const formData = new FormData();
    for (const key in bankImage) {
      formData.append(key, bankImage[key]);
    }
    await axios.put("/bank-images/" + bankImage.id, formData);
    dispatch(fetchBankImages());
  } catch (err) {
    dispatch(editBankImageFailed(err));
    throw err;
  }
};
