import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import AddButton from "../../components/UI/CustomButtons/AddButton";
import CustomDialog from "../../components/UI/CustomDialog/CustomDialog";
import Spinner from "../../components/UI/Spinner/Spinner";
import TableList from "../../components/UI/TableList/TableList";
import * as actions from "../../store/actions";

class BankImages extends Component {
  state = {
    bankImageToDelete: null
  };

  componentDidMount() {
    this.props.onFetchBankImages();
  }

  handlePageClick = ({ currentPage }) => this.setState({ currentPage });

  handleShowDetail = imageId => {
    const bankImage = this.props.bankImages.find(p => p.id === imageId);
    this.props.history.push({
      pathname: `/banco-de-imagenes/${imageId}`,
      state: { bankImage }
    });
  };

  handleCreateBankImage = () => {
    this.props.history.push({
      pathname: "/banco-de-imagenes/crear",
      state: { editMode: false }
    });
  };

  handleEditBankImage = imageId => {
    const bankImage = this.props.bankImages.find(p => p.id === imageId);
    this.props.history.push({
      pathname: `/banco-de-imagenes/${imageId}/editar`,
      state: { bankImage, editMode: true }
    });
  };

  handleDeleteBankImage = imageId => {
    this.setState({ bankImageToDelete: imageId });
  };

  deleteBankImage = () => {
    this.props.onDeleteBankImage(this.state.bankImageToDelete);
    this.setState({ bankImageToDelete: null });
  };

  handleCloseDialog = () => this.setState({ bankImageToDelete: null });

  handleUrlClick = url => {
    navigator.clipboard.writeText(url);
    this.props.setToast("URL copiada con éxito al portapapeles");
  };

  render() {
    let bankImages = null;
    if (this.props.bankImages) {
      const options = this.props.bankImages.map(bankImage => {
        const url =
          process.env.REACT_APP_BACKEND_BASE_URL + bankImage["imageUrl"];
        return [
          bankImage["id"],
          bankImage["name"],
          url,
          bankImage["description"],
          bankImage["classification"]
        ];
      });

      bankImages = (
        <TableList
          title="Banco de imagenes"
          head={[
            "Id",
            "Nombre de la imagen",
            "URL",
            "Descripción",
            "Clasifición",
            ""
          ]}
          onShowDetail={this.handleShowDetail}
          onEdit={this.handleEditBankImage}
          onDelete={this.handleDeleteBankImage}
          detail
          edit
          delete
          showURL
          data={options}
          handleUrlClick={this.handleUrlClick}
        />
      );
    } else if (this.props.loading) {
      bankImages = <Spinner />;
    }

    return (
      <Fragment>
        {bankImages}
        <AddButton onClick={this.handleCreateBankImage} />
        <CustomDialog
          title={"Confirmar"}
          description={"¿Estás seguro que deseas eliminar esta imagen?"}
          open={this.state.bankImageToDelete != null}
          onConfirmation={this.deleteBankImage}
          handleClose={this.handleCloseDialog}
          okButtonText={"Eliminar"}
          cancelButtonText={"Cancelar"}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    bankImages: state.bankImages.bankImages,
    loading: state.bankImages.loading
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchBankImages: () => dispatch(actions.fetchBankImages()),
  onDeleteBankImage: bankImageId =>
    dispatch(actions.deleteBankImage(bankImageId)),
  setToast: (message, messageType) =>
    dispatch(actions.setToast(message, messageType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankImages);
