import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchInsurancesStart = () => {
  return {
    type: actionTypes.FETCH_INSURANCES_START
  };
};

const fetchInsurancesSuccess = insurances => {
  return {
    type: actionTypes.FETCH_INSURANCES_SUCCESS,
    payload: insurances
  };
};

const fetchInsurancesFailed = error => {
  return {
    type: actionTypes.FETCH_INSURANCES_FAILED,
    error: error
  };
};

export const fetchInsurances = () => async dispatch => {
  dispatch(fetchInsurancesStart());
  try {
    const { data } = await axios.get('/insurances');
    dispatch(fetchInsurancesSuccess(data.data));
  } catch (err) {
    dispatch(fetchInsurancesFailed(err));
  }
};

const createInsuranceFailed = error => {
  return {
    type: actionTypes.CREATE_INSURANCE_FAILED,
    error: error
  };
};

export const createInsurance = insurance => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('name', insurance.name);
    formData.append('front', insurance.front);
    formData.append('back', insurance.back);
    formData.append('optional', insurance.optional);
    await axios.post('/insurances', formData);
    dispatch(fetchInsurances());
  } catch (err) {
    dispatch(createInsuranceFailed(err));
  }
};

const deleteInsuranceFailed = err => {
  return {
    type: actionTypes.DELETE_INSURANCE_FAILED,
    error: err
  };
};

export const deleteInsurance = insuranceId => async dispatch => {
  try {
    await axios.delete('/insurances/' + insuranceId);
    dispatch(fetchInsurances());
  } catch (err) {
    dispatch(deleteInsuranceFailed(err));
  }
};

const editInsuranceFailed = err => {
  return {
    type: actionTypes.EDIT_INSURANCE_FAILED,
    error: err
  };
};

export const editInsurance = insurance => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('name', insurance.name);
    formData.append('front', insurance.front);
    formData.append('back', insurance.back);
    formData.append('optional', insurance.optional);
    await axios.patch('/insurances/' + insurance._id, formData);
    dispatch(fetchInsurances());
  } catch (err) {
    dispatch(editInsuranceFailed(err));
  }
};