import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Close, Description, Edit } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import tableStyle from '../../../assets/jss/components/tableStyle';

function CustomTable({ ...props }) {
  const {
    classes,
    tableHead,
    tableData,
    tableHeaderColor,
    showURL,
    handleUrlClick,
    selectable,
    selectableIndex,
    selected,
    onSelect,
    showSelectAll = false,
  } = props;

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = tableData.map(prop => prop[0]);
      onSelect(newSelecteds);
    } else {
      onSelect([]);
    }
  };

  const handleSelectClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    onSelect(newSelected);
  };

  let firstSelectedRow = null;

  if (selectable && selected && selected.length > 0) {
    firstSelectedRow = tableData.find(row => row[0] === selected[0]);
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow>
              {selectable ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    disabled={!showSelectAll}
                    indeterminate={selected.length > 0 && selected.length < tableData.length}
                    color="primary"
                    checked={tableData.length > 0 && selected.length === tableData.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              ) : null}
              {tableHead.map((prop, key) => {
                return prop !== 'Id' ? (
                  <TableCell className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}>
                    {prop}
                  </TableCell>
                ) : null;
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            const isSelected = selectable && selected && selected.some(id => id === prop[0]);
            let canBeSelected = true;
            if (selectable && selected && selected.length > 0 && firstSelectedRow) {
              canBeSelected = firstSelectedRow[selectableIndex] === prop[selectableIndex];
            }

            return (
              <TableRow
                key={key}
                hover={selectable && canBeSelected}
                role={selectable ? 'checkbox' : ''}
                selected={selectable && isSelected}
                aria-checked={selectable && isSelected}
                onClick={event => {
                  if (selectable && canBeSelected) {
                    handleSelectClick(event, prop[0]);
                  }
                }}
                style={{
                  backgroundColor:
                    props.highlightDeleted && prop[prop.length - 1] === 'Eliminado' && 'rgba(0,0,0,0.3)',
                }}
              >
                {selectable ? (
                  <TableCell padding="checkbox">
                    <Checkbox disabled={!canBeSelected} checked={isSelected} color="primary" />
                  </TableCell>
                ) : null}
                {prop.map((data, key) => {
                  const isClickable = props.clickable && key > 2;
                  return key !== 0 ? (
                    <TableCell
                      className={classNames(classes.tableCell, showURL && classes.pointer)}
                      key={key}
                      onClick={
                        showURL &&
                        data &&
                        typeof data === 'string' &&
                        data.includes('http') &&
                        (() => handleUrlClick(data))
                      }
                      style={{
                        cursor: isClickable ? 'pointer' : 'auto',
                      }}
                    >
                      {data && data.startsWith('http') && !showURL ? (
                        <a href={data} target="_blank" rel="noopener noreferrer">
                          Link para visualizar
                        </a>
                      ) : (
                        data
                      )}
                    </TableCell>
                  ) : null;
                })}
                <TableCell className={classes.tableActions}>
                  {props.detail ? (
                    <Tooltip
                      id="tooltip-top"
                      title="Detalle"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Detalle"
                        className={classes.tableActionButton}
                        onClick={props.onShowDetail.bind(this, prop[0])}
                      >
                        <Description className={classes.tableActionButtonIcon + ' ' + classes.edit} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {props.edit ? (
                    <Tooltip
                      id="tooltip-top"
                      title="Editar"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Editar"
                        className={classes.tableActionButton}
                        onClick={props.onEdit.bind(this, prop[0])}
                      >
                        <Edit className={classes.tableActionButtonIcon + ' ' + classes.edit} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {props.delete ? (
                    <Tooltip
                      id="tooltip-top-start"
                      title="Eliminar"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Eliminar"
                        className={classes.tableActionButton}
                        onClick={props.onDelete.bind(this, prop[0])}
                      >
                        <Close className={classes.tableActionButtonIcon + ' ' + classes.close} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf(['warning', 'primary', 'danger', 'success', 'info', 'rose', 'gray']),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default withStyles(tableStyle)(CustomTable);
