import * as actionTypes from '../actions/actionTypes'

const initialState = {
  discounts: null,
  uploaded: false,
  loading: false,
  createdDiscountsCount: 0,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DISCOUNTS_START:
      return {
        ...state,
        loading: true,
        uploaded: false,
        error: null,
      }
    case actionTypes.FETCH_DISCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        discounts: action.payload,
      }
    case actionTypes.FETCH_DISCOUNTS_FAILED:
      return {
        ...state,
        loading: false,
        uploaded: false,
        error: action.error,
      }
    case actionTypes.CREATE_DISCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        uploaded: true,
        createdDiscountsCount: action.createdDiscountsCount,
        error: null,
      }
    case actionTypes.CREATE_DISCOUNTS_FAILED:
      return {
        ...state,
        loading: false,
        uploaded: false,
        createdDiscountsCount: action.createdDiscountsCount,
        error: action.error,
      }
    case actionTypes.RESET_DISCOUNTS_UPLOADED:
      return {
        ...state,
        uploaded: false,
        createdDiscountsCount: 0,
      }
    default:
      return state
  }
}
