import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkValidity } from '../../shared/validation';
import * as actions from '../../store/actions';
// components
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
// material ui components
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
// styles
import classes from './Login.css';

class Login extends Component {
  state = {
    controls: {
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Email'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Contraseña'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: false
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push('/banners');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthenticated) {
      const { next } = this.props.location.state || { next: '/banners' };
      this.props.history.push(next);
    }
  }

  inputChangedHandler = (value, controlName) => {
    const updatedControls = { ...this.state.controls };
    const updatedFormElement = { ...updatedControls[controlName] };
    updatedFormElement.value = value;
    updatedFormElement.valid = checkValidity(
      value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedControls[controlName] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }
    this.setState({ controls: updatedControls, formIsValid });
  };

  submitFormHandler = event => {
    event.preventDefault();
    const authData = {
      email: this.state.controls.email.value,
      password: this.state.controls.password.value
    };
    this.props.onLogin(authData);
  };

  render() {
    const formElements = Object.keys(this.state.controls).map(key => {
      const formElement = this.state.controls[key];
      return (
        <div className={classes.InputContainer} key={key}>
          <Input
            element={formElement}
            onChange={value => this.inputChangedHandler(value, key)}
          />
        </div>
      );
    });

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p className={classes.ErrorMessage}>{this.props.error}</p>;
    }

    let form = (
      <form onSubmit={this.submitFormHandler} noValidate autoComplete="off">
        {formElements}
        <Button
          className={classes.SubmitButton}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!this.state.formIsValid}
        >
          Entrar
        </Button>
        {errorMessage}
      </form>
    );

    if (this.props.loading) {
      form = <Spinner />;
    }

    return (
      <div className={classes.Auth}>
        <div className={classes.AuthFormContainer}>
          <div className={classes.AuthFormSubContainer}>
            <img
              className={classes.Logo}
              src={require('../../assets/images/cdlc_footer_logo.png')}
              alt="Credito de la Casa"
            />
            <Card className={classes.AuthForm}>{form}</Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    error: state.auth.error
  };
};

const mapDispatchToProps = dispatch => ({
  onLogin: authData => dispatch(actions.login(authData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
