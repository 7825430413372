import * as actionTypes from '../actions/actionTypes';

const initialState = {
  faqs: null,
  loading: false,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_FAQS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_FAQS_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
        loading: false
      };
    case actionTypes.FETCH_FAQS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}