import React, { Component } from 'react';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import axios from '../../axios';
// material ui
import { AppBar, Tabs, Tab } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon
} from '@material-ui/icons';
// components
import Button from '../../components/UI/CustomButtons/Button';
import GridContainer from '../../components/UI/Grid/GridContainer';
import GridItem from '../../components/UI/Grid/GridItem';
import TextEditor from '../../components/UI/TextEditor/TextEditor';
import Spinner from '../../components/UI/Spinner/Spinner';
import Snackbar from '../../components/UI/Snackbar/Snackbar';
// styles
import classes from './Settings.css';

const createEditorState = text => {
  try {
    const raw = JSON.parse(text);
    return EditorState.createWithContent(convertFromRaw(raw));
  } catch (error) {
    return EditorState.createEmpty();
  }
};

const convertToString = state => {
  return JSON.stringify(convertToRaw(state.getCurrentContent()));
};

class Settings extends Component {
  state = {
    editors: {
      history: {
        tab: 0,
        title: null,
        state: EditorState.createEmpty()
      },
      mission: {
        tab: 1,
        title: 'Misión',
        state: EditorState.createEmpty()
      },
      vision: {
        tab: 1,
        title: 'Visión',
        state: EditorState.createEmpty()
      },
      goodPracticeCode: {
        tab: 2,
        title: null,
        state: EditorState.createEmpty()
      },
      businessHours: {
        tab: 3,
        title: 'Horario de atención',
        state: EditorState.createEmpty()
      },
      phoneNumber: {
        tab: 3,
        title: 'Teléfono',
        state: EditorState.createEmpty()
      }
    },
    snackbar: false,
    loadingSettings: false,
    loadingSaveSettings: false,
    saveSettingsError: false,
    tabValue: 0
  };

  async componentDidMount() {
    this.setState({ loadingSettings: true });
    try {
      const data = await axios.get('/settings').then(res => res.data.data);
      if (data) {
        this.setState(prevState => {
          return {
            editors: {
              mission: {
                ...prevState.editors.mission,
                state: createEditorState(data.missionText)
              },
              vision: {
                ...prevState.editors.vision,
                state: createEditorState(data.visionText)
              },
              history: {
                ...prevState.editors.history,
                state: createEditorState(data.historyText)
              },
              goodPracticeCode: {
                ...prevState.editors.goodPracticeCode,
                state: createEditorState(data.goodPracticeCodeText)
              },
              businessHours: {
                ...prevState.editors.businessHours,
                state: createEditorState(data.businessHours)
              },
              phoneNumber: {
                ...prevState.editors.phoneNumber,
                state: createEditorState(data.phoneNumber)
              }
            }
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loadingSettings: false });
  }

  onTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  onEditorChange = (name, editorState) => {
    this.setState(prevState => {
      return {
        editors: {
          ...prevState.editors,
          [name]: { ...prevState.editors[name], state: editorState }
        }
      };
    });
  };

  onSettingsSave = async () => {
    this.setState({ loadingSaveSettings: true, saveSettingsError: false });
    try {
      await axios.patch('/settings', {
        missionText: convertToString(this.state.editors.mission.state),
        visionText: convertToString(this.state.editors.vision.state),
        historyText: convertToString(this.state.editors.history.state),
        goodPracticeCodeText: convertToString(
          this.state.editors.goodPracticeCode.state
        ),
        businessHours: convertToString(this.state.editors.businessHours.state),
        phoneNumber: convertToString(this.state.editors.phoneNumber.state)
      });
    } catch (error) {
      this.setState({ saveSettingsError: true });
    }
    this.setState({ loadingSaveSettings: false, snackbar: true });
  };

  render() {
    let submitButton = (
      <Button
        className={classes.SubmitButton}
        color="primary"
        onClick={this.onSettingsSave}
      >
        Guardar Configuración
      </Button>
    );

    if (this.state.loadingSaveSettings) {
      submitButton = (
        <div className={classes.SubmitButton}>
          <Spinner size={50} />
        </div>
      );
    }

    const textEditors = Object.entries(this.state.editors)
      .filter(([_, value]) => value.tab === this.state.tabValue)
      .map(([key, value]) => (
        <GridItem className={classes.EditorContainer}>
          <h1>{value.title}</h1>
          <TextEditor
            editorState={value.state}
            onChange={state => this.onEditorChange(key, state)}
          />
        </GridItem>
      ));

    let tabContent = (
      <div className={classes.TabContent}>
        <GridContainer justify="center">{textEditors}</GridContainer>
        {submitButton}
      </div>
    );

    if (this.state.loadingSettings) {
      tabContent = (
        <div className={classes.LoadingContainer}>
          <Spinner />
        </div>
      );
    }

    let snackbarType = 'success';
    let snackbarIcon = CheckCircleIcon;
    let snackbarMessage = 'La configuración fue guardada con éxito';

    if (this.state.saveSettingsError) {
      snackbarType = 'danger';
      snackbarIcon = ErrorIcon;
      snackbarMessage = 'Error al guardar la configuración';
    }

    return (
      <React.Fragment>
        <div className={classes.TabsContainer}>
          <AppBar position="static">
            <Tabs value={this.state.tabValue} onChange={this.onTabChange}>
              <Tab label="HISTORIA" />
              <Tab label="MISIÓN Y VISIÓN" />
              <Tab label="CÓDIGO DE BUENAS PRÁCTICAS" />
              <Tab label="FOOTER" />
            </Tabs>
          </AppBar>
          {tabContent}
        </div>
        <Snackbar
          place="bl"
          color={snackbarType}
          icon={snackbarIcon}
          message={snackbarMessage}
          open={this.state.snackbar}
          closeNotification={() => this.setState({ snackbar: false })}
          close
        />
      </React.Fragment>
    );
  }
}

export default Settings;
