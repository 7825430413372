import * as actionTypes from '../actions/actionTypes'

const initialState = {
  banners: null,
  loading: false,
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_BANNERS_START:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.FETCH_BANNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        banners: action.payload,
      }
    case actionTypes.FETCH_BANNERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
