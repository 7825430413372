import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
// containers
import Login from './Auth/Login';
// HOC
import Layout from '../hoc/Layout/Layout';
// routes
import indexRoutes from '../routes/index';

class App extends Component {
  componentDidMount() {
    this.props.onLoadUser();
  }

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          this.props.isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/ingreso',
                state: { next: props.location.pathname }
              }}
            />
          )
        }
      />
    );

    if (this.props.loadingUser) {
      return null;
    }

    return (
      <Switch>
        <Route path="/ingreso" component={Login} />
        <Layout onLogout={this.props.onLogout}>
          <Switch>
            {indexRoutes.map((prop, key) => (
              <PrivateRoute
                path={prop.path}
                component={prop.component}
                key={key}
              />
            ))}
            <Redirect from="/" to="/banners" />
          </Switch>
        </Layout>
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loadingUser: state.auth.loadingUser
});

const mapDispatchToProps = dispatch => ({
  onLoadUser: () => dispatch(actions.loadUser()),
  onLogout: () => dispatch(actions.logout())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
