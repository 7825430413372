import React from 'react'
import classNames from "classnames"
// components
import Card from "../Card/Card"
import CardHeader from "../Card/CardHeader"
import CardBody from "../Card/CardBody"
// material ui core components
import withStyles from "@material-ui/core/styles/withStyles"
import IconButton from '@material-ui/core/IconButton'
// material ui icons
import Edit from '@material-ui/icons/Edit'
// styles
import userStyle from '../../../assets/jss/containers/listsContainer'
import myClasses from './EditableTitleSection.css'

const EditableTitleSection = (props) => {

  const { classes } = props

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classNames(classes.cardTitleWhite, myClasses.SectionDescriptionTitle)}>{props.title}</h4>
        <IconButton
          className={myClasses.EditButton}
          onClick={props.onClick}
        >
          <Edit />
        </IconButton>
      </CardHeader>
      <CardBody>
        <div className={myClasses.SectionDescription} dangerouslySetInnerHTML={{ __html: props.html }} />
      </CardBody>
    </Card>
  )
}

export default withStyles(userStyle)(EditableTitleSection)