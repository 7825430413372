import axios from '../../axios'
import * as actionTypes from './actionTypes'

const rseBaseRoute = '/rse';

export const fetchRseSuccess = (rse) => {
  let filteredBanners = [];
  for (let banner of rse.banners) {
    if (!banner.deleted) {
      filteredBanners.push(banner);
    }
  }
  rse.banners = filteredBanners;
  return {
    type: actionTypes.FETCH_RSE_SUCCESS,
    rse
  };
};

export const fetchRseFailed = () => {
  return {
    type: actionTypes.FETCH_RSE_FAILED
  }
};

export const fetchRse = () => {
  return dispatch => {
    axios.get(rseBaseRoute)
      .then(response => {
        dispatch(fetchRseSuccess(response.data.data))
      })
      .catch(err => {
        dispatch(fetchRseFailed());
      });
  };
};

export const editRseText = (text) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(rseBaseRoute, { text })
        .then(response => {
          dispatch(fetchRse())
          resolve(response);
        })
        .catch(err => {
          dispatch(editRseTextFailed())
        })
    })
  };
};

export const editRseTextFailed = () => {
  return {
    type: actionTypes.EDIT_RSE_TEXT_FAILED
  }
};

export const createRseBanner = rseBanner => async dispatch => {
  try {
    const formData = new FormData()
    for (const key in rseBanner) {
      formData.append(key, rseBanner[key])
    }
    await axios.post(rseBaseRoute + '/banners', formData)
    dispatch(fetchRse())
  } catch (err) {
    dispatch(createRseBannerFailed(err))
  }
};

export const createRseBannerFailed = () => {
  return {
    type: actionTypes.CREATE_RSE_BANNER_FAILED
  }
};

export const editRseBanner = banner => async dispatch => {
  try {
    const formData = new FormData()
    for (const key in banner) {
      formData.append(key, banner[key])
    }
    await axios.put(rseBaseRoute + '/banners/' + banner._id, formData)
    dispatch(fetchRse())
  } catch (err) {
    dispatch(editRseBannerFailed(err))
  }
}

export const editRseBannerFailed = () => {
  return {
    type: actionTypes.EDIT_RSE_BANNER_FAILED
  }
};

const deleteRseBannerFailed = error => {
  return {
    type: actionTypes.DELETE_RSE_BANNER_FAILED,
    error,
  }
}

export const deleteRseBanner = bannerId => async dispatch => {
  try {
    await axios.delete(rseBaseRoute + '/banners/' + bannerId)
    dispatch(fetchRse())
  } catch (err) {
    dispatch(deleteRseBannerFailed(err))
  }
}