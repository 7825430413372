import React, { Component } from "react";
import { connect } from "react-redux";
import placeholder from "../../../assets/images/placeholder.png";
import Card from "../../../components/UI/Card/Card";
import CardBody from "../../../components/UI/Card/CardBody";
import CardFooter from "../../../components/UI/Card/CardFooter";
import Button from "../../../components/UI/CustomButtons/Button";
import CustomInput from "../../../components/UI/CustomInput/CustomInput";
import GridContainer from "../../../components/UI/Grid/GridContainer";
import GridItem from "../../../components/UI/Grid/GridItem";
import * as actions from "../../../store/actions";
import classes from "./BankImageForm.css";

class BankImageForm extends Component {
  constructor(props) {
    super(props);
    this.editMode = this.props.location.state.editMode;
    this.state = {
      bankImage: this.editMode
        ? { ...this.props.location.state.bankImage, file: null }
        : { name: "", description: "", classification: "", file: null }
    };
  }

  inputChangedHandler = (event, inputIdentifier) => {
    let newBankImage = { ...this.state.bankImage };
    if (inputIdentifier === "file") {
      newBankImage[inputIdentifier] = event.target.files[0];
    } else {
      newBankImage[inputIdentifier] = event.target.value;
    }
    this.setState({ bankImage: newBankImage });
  };

  handleSubmit = async () => {
    try {
      if (this.editMode) {
        await this.props.onEditBankImage(this.state.bankImage);
      } else {
        await this.props.onCreateBankImage(this.state.bankImage);
      }
      this.props.setToast(
        `La imagen fue ${this.editMode ? "editada" : "creada"} con éxito`
      );
      this.props.history.push({ pathname: "/banco-de-imagenes" });
    } catch (error) {
      this.props.setToast(
        `Se produjo un error al ${
          this.editMode ? "editar" : "creada"
        } la imagen`,
        "danger"
      );
    }
  };

  render() {
    const buttonIsDisabled =
      this.state.bankImage.name === "" ||
      this.state.bankImage.image === null ||
      this.state.bankImage.classification === "";

    const bankImage = this.state.bankImage.file
      ? URL.createObjectURL(this.state.bankImage.file)
      : this.editMode
        ? `${process.env.REACT_APP_BACKEND_BASE_URL}${
            this.state.bankImage.imageUrl
          }`
        : placeholder;

    return (
      <Card profile>
        <CardBody profile>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Título de la imagen"
                id="name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: this.state.bankImage.name,
                  onChange: event => this.inputChangedHandler(event, "name")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Descripción"
                id="description"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: this.state.bankImage.description,
                  onChange: event =>
                    this.inputChangedHandler(event, "description")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Clasificación"
                id="classification"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: this.state.bankImage.classification,
                  onChange: event =>
                    this.inputChangedHandler(event, "classification")
                }}
              />
            </GridItem>
          </GridContainer>
          <h2 className={classes.H2Image}>Imagen</h2>
          <p>*Formatos permitidos: jpeg, jpg, png, tiff, ico, svg, gif</p>
          <GridContainer alignItems="center" justify="center">
            <GridItem xs={12} md={6}>
              <img className={classes.Image} src={bankImage} alt="Imagen" />
            </GridItem>
            <GridItem xs={12} md={6}>
              <input
                type="file"
                onChange={event => this.inputChangedHandler(event, "file")}
                accept=".jpeg, .jpg, .png, .tiff, .ico, .svg, .svg+xml, .gif" 
                multiple={false}
                className={classes.InputImage}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            className={classes.SubmitButton}
            disabled={buttonIsDisabled}
            onClick={this.handleSubmit}
          >
            {this.editMode ? "ACTUALIZAR" : "CREAR"} Imagen
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateBankImage: bankImage => dispatch(actions.createBankImage(bankImage)),
  onEditBankImage: bankImage => dispatch(actions.editBankImage(bankImage)),
  setToast: (message, messageType) =>
    dispatch(actions.setToast(message, messageType))
});

export default connect(
  null,
  mapDispatchToProps
)(BankImageForm);
