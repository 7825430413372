import * as actionTypes from './actionTypes';

export const setToast = (message, messageType = 'success') => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TOAST,
    message,
    messageType,
  });
  setTimeout(() => {
    dispatch(removeToast());
  }, 5000);
};

export const removeToast = () => {
  return {
    type: actionTypes.REMOVE_TOAST,
  };
};
