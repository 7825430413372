import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as documentationActions from '../../store/actions/index'
// components
import AddButton from '../../components/UI/CustomButtons/AddButton'
import TableList from '../../components/UI/TableList/TableList'
import Spinner from '../../components/UI/Spinner/Spinner'
import Pagination from '../../components/UI/Pagination/Pagination'
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog'

import { convertDocumentTypeToString } from '../../enums/documentType'

const PAGE_LIMIT = 12

class Documentation extends Component {
  state = {
    currentPage: 1,
    documentToDelete: null,
  }

  componentDidMount() {
    this.props.onGetDocuments()
  }

  handlePageClick = ({ currentPage }) => this.setState({ currentPage })

  handleShowDetails = documentId => {
    const document = this.props.documents.find(doc => doc._id === documentId)
    const url = process.env.REACT_APP_BACKEND_BASE_URL + document.file
    window.open(url, '_blank')
  }

  handleCreateDocument = () => {
    this.props.history.push({
      pathname: '/documentos/crear',
      state: {
        editMode: false,
      },
    })
  }

  handleEditDocument = documentId => {
    const document = this.props.documents.find(doc => doc._id === documentId)
    this.props.history.push({
      pathname: '/documentos/' + documentId + '/editar',
      state: {
        document,
        editMode: true,
      },
    })
  }

  handleDeleteDocument = documentId => {
    this.setState({ documentToDelete: documentId })
  }

  deleteDocument = () => {
    this.props.deleteDocument(this.state.documentToDelete)
    this.setState({ documentToDelete: null })
  }

  handleDeleteCancelled = () => {
    this.setState({ documentToDelete: null })
  }

  render() {
    let documents = null
    if (this.props.documents) {
      const options = this.props.documents
        .slice(
          (this.state.currentPage - 1) * PAGE_LIMIT,
          this.state.currentPage * PAGE_LIMIT,
        )
        .map(doc => [
          doc['_id'],
          doc['title'],
          convertDocumentTypeToString(doc['group']),
        ])

      documents = (
        <TableList
          title="Documentos"
          head={['Id', 'Título', 'Grupo', '']}
          onShowDetail={this.handleShowDetails}
          onEdit={this.handleEditDocument}
          onDelete={this.handleDeleteDocument}
          detail
          edit
          delete
          data={options}
          pagination={
            <Pagination
              totalRecords={this.props.documents.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={10}
              onPageChanged={this.handlePageClick}
            />
          }
        />
      )
    } else {
      documents = <Spinner />
    }

    return (
      <Fragment>
        {documents}
        <AddButton onClick={this.handleCreateDocument} />

        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar este documento?'}
          open={this.state.documentToDelete != null}
          onConfirmation={this.deleteDocument}
          handleClose={this.handleDeleteCancelled}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  documents: state.documents.documents,
})

const mapDispatchToProps = dispatch => ({
  onGetDocuments: () => dispatch(documentationActions.getDocuments()),
  deleteDocument: id => dispatch(documentationActions.deleteDocument(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documentation)
