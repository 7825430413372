import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon
} from '@material-ui/icons';
import * as actions from '../../../store/actions';
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import CustomInput from '../../../components/UI/CustomInput/CustomInput';
import Button from '../../../components/UI/CustomButtons/Button';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import Snackbar from '../../../components/UI/Snackbar/Snackbar';
import classes from './CreateFaq.css';

class CreateFaq extends Component {
  constructor(props) {
    super(props);
    this.editMode = this.props.location.state.editMode;
    this.state = {
      faqs: this.editMode
        ? {
            ...this.props.location.state.faq,
            question: this.props.location.state.faq.question,
            answer: this.props.location.state.faq.answer,
            order: +this.props.location.state.faq.order
          }
        : { question: '', answer: '', order: '' },
      snackbar: false,
      errorMessage: null,
      error: false
    };
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newFaq = { ...this.state.faqs };
    newFaq[inputIdentifier] = event.target.value;
    this.setState({ faqs: newFaq });
  };

  handleSubmit = async () => {
    if (
      [
        this.state.faqs.question,
        this.state.faqs.answer,
        this.state.faqs.order
      ].includes('')
    ) {
      this.showSnackbar(true, 'Rellenar todos los campos');
    } else {
      if (this.editMode) {
        await this.props.onEditFaq(this.state.faqs);
      } else {
        await this.props.onCreateFaq(this.state.faqs);
      }
      this.showSnackbar(false);
      this.props.history.push({ pathname: '/faqs' });
    }
  };

  showSnackbar = (error, message) => {
    this.setState({
      snackbar: true,
      error: error,
      errorMessage: message
    });
  };

  render() {
    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Pregunta"
                  id="question"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.faqs.question,
                    onChange: event =>
                      this.inputChangedHandler(event, 'question')
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Respuesta"
                  id="answer"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.faqs.answer,
                    onChange: event => this.inputChangedHandler(event, 'answer')
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Orden"
                  id="order"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.faqs.order,
                    onChange: event => this.inputChangedHandler(event, 'order')
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className={classes.SubmitButton}
              onClick={this.handleSubmit}
            >
              {this.editMode ? 'ACTUALIZAR' : 'CREAR'} Tarjeta FAQ
            </Button>
          </CardFooter>
        </Card>
        <Snackbar
          place="bl"
          color={this.state.error ? 'danger' : 'success'}
          icon={this.state.error ? ErrorIcon : CheckCircleIcon}
          message={
            this.state.error
              ? this.state.errorMessage
              : `La tarjeta FAQ fue ${
                  this.state.editMode ? 'editada' : 'creada'
                } con éxito`
          }
          open={this.state.snackbar}
          closeNotification={() => this.setState({ snackbar: false })}
          close
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateFaq: faq => dispatch(actions.createFaq(faq)),
  onEditFaq: faq => dispatch(actions.editFaq(faq))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateFaq);
