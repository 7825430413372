import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
//components
import AddButton from '../../components/UI/CustomButtons/AddButton';
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';

class Faqs extends Component {
  state = {
    faqToDelete: null
  };

  componentDidMount() {
    this.props.onFetchFaqs();
  }

  handleCreateFaq = () => {
    this.props.history.push({
      pathname: '/faqs/crear',
      state: {
        editMode: false
      }
    });
  };

  handleEditFaq = faqId => {
    const faq = this.props.faqs.find(b => b._id === faqId);
    this.props.history.push({
      pathname: '/faqs/' + faqId + '/editar',
      state: {
        faq,
        editMode: true
      }
    });
  };

  handleDeleteFaq = faqId => {
    this.setState({ faqToDelete: faqId });
  };

  deleteFaq = () => {
    this.props.onDeleteFaq(this.state.faqToDelete);
    this.setState({ faqToDelete: null });
  };

  handleCloseDialog = () => {
    this.setState({ faqToDelete: null });
  };

  render() {
    let faqs = null;
    if (this.props.faqs) {
      const options = this.props.faqs.map(faq => {
        return [
          faq['_id'],
          faq['question'],
          faq['answer'],
          faq['order'].toString()
        ];
      });
      faqs = (
        <TableList
          title="FAQs Tarjetas"
          head={['Id', 'Pregunta', 'Respuesta', 'Orden', '']}
          onEdit={this.handleEditFaq}
          onDelete={this.handleDeleteFaq}
          edit
          delete
          data={options}
        />
      );
    } else if (this.props.loading) {
      faqs = <Spinner />;
    }
    return (
      <Fragment>
        {faqs}
        <AddButton onClick={this.handleCreateFaq} />
        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar esta FAQ?'}
          open={this.state.faqToDelete !== null}
          onConfirmation={this.deleteFaq}
          handleClose={this.handleCloseDialog}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    faqs: state.faq.faqs,
    loading: state.faq.loading
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchFaqs: () => dispatch(actions.fetchFaqs()),
  onDeleteFaq: faqId => dispatch(actions.deleteFaq(faqId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Faqs);