import * as actionTypes from '../actions/actionTypes';

const initialState = {
  characteristics: null,
  loading: false,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CHARACTERISTICS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_CHARACTERISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        characteristics: action.payload
      };
    case actionTypes.FETCH_CHARACTERISTICS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}