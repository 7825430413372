import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
// components
import AddButton from '../../components/UI/CustomButtons/AddButton';
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';

const PAGE_LIMIT = 12;

class Banners extends Component {
  state = {
    currentPage: 1,
    bannerToDelete: null
  };

  componentDidMount() {
    this.props.onFetchBanners();
  }

  handlePageClick = ({ currentPage }) => this.setState({ currentPage });

  handleCreateBanner = () => {
    this.props.history.push({
      pathname: '/banners/crear',
      state: {
        editMode: false
      }
    });
  };

  handleEditBanner = bannerId => {
    const banner = this.props.banners.find(b => b.id === bannerId);
    this.props.history.push({
      pathname: '/banners/' + bannerId + '/editar',
      state: {
        banner,
        editMode: true
      }
    });
  };

  handleDeleteBanner = bannerId => {
    this.setState({ bannerToDelete: bannerId });
  };

  deleteBanner = () => {
    this.props.onDeleteBanner(this.state.bannerToDelete);
    this.setState({ bannerToDelete: null });
  };

  handleCloseDialog = () => {
    this.setState({ bannerToDelete: null });
  };

  handleSaveSort = async ids => {
    actions.updateOrder(ids);
  };

  render() {
    let banners = null;
    if (this.props.banners) {
      const options = this.props.banners
        .slice(
          (this.state.currentPage - 1) * PAGE_LIMIT,
          this.state.currentPage * PAGE_LIMIT
        )
        .map(banner => {
          return [
            banner['id'],
            banner['name'],
            process.env.REACT_APP_BACKEND_BASE_URL + banner['imageUrl'],
            process.env.REACT_APP_BACKEND_BASE_URL +
              banner['imageResponsiveUrl']
          ];
        });

      banners = (
        <TableList
          title="Banners"
          head={[
            'Id',
            'Nombre del Banner',
            'Banner para Desktop',
            'Banner para Mobile',
            ''
          ]}
          onEdit={this.handleEditBanner}
          onDelete={this.handleDeleteBanner}
          edit
          delete
          data={options}
          sortable
          onSaveSort={this.handleSaveSort}
          pagination={
            <Pagination
              totalRecords={this.props.banners.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={10}
              onPageChanged={this.handlePageClick}
            />
          }
        />
      );
    } else if (this.props.loading) {
      banners = <Spinner />;
    }

    return (
      <Fragment>
        {banners}
        <AddButton onClick={this.handleCreateBanner} />

        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar este banner?'}
          open={this.state.bannerToDelete != null}
          onConfirmation={this.deleteBanner}
          handleClose={this.handleCloseDialog}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
    loading: state.banners.loading
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchBanners: () => dispatch(actions.fetchBanners()),
  onDeleteBanner: bannerId => dispatch(actions.deleteBanner(bannerId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Banners);
