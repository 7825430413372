import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
// core components
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import CustomInput from '../../../components/UI/CustomInput/CustomInput';
import Button from '../../../components/UI/CustomButtons/Button';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import Snackbar from '../../../components/UI/Snackbar/Snackbar';
// @material-ui/icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import placeholder from '../../../assets/images/placeholder.png';

class CreateInsurance extends Component {
  constructor(props) {
    super(props);
    this.editMode = this.props.location.state.editMode;

    this.state = {
      insurance: this.editMode
        ? {
            ...this.props.location.state.insurance,
            front: null,
            back: null,
            optional: null
          }
        : { name: '', front: null, back: null, optional: null },
      snackbar: false
    };
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newInsurance = { ...this.state.insurance };
    newInsurance[inputIdentifier] = event.target.value;
    this.setState({ insurance: newInsurance });
  };

  fileChangedHandler = (event, inputIdentifier) => {
    const newInsurance = {
      ...this.state.insurance,
      [inputIdentifier]: event.target.files[0]
    };
    this.setState({ insurance: newInsurance });
  };

  handleSubmit = async () => {
    if (this.editMode) {
      await this.props.onEditInsurance(this.state.insurance);
      this.showSuccessSnackbar();
    } else {
      await this.props.onCreateInsurance(this.state.insurance);
      this.showSuccessSnackbar();
    }
  };

  showSuccessSnackbar = () => {
    this.setState({ snackbar: true });
  };

  render() {
    const frontImg = this.state.insurance.front
      ? URL.createObjectURL(this.state.insurance.front)
      : this.editMode
        ? process.env.REACT_APP_BACKEND_BASE_URL +
          this.state.insurance.frontImageUrl
        : placeholder;

    const backImg = this.state.insurance.back
      ? URL.createObjectURL(this.state.insurance.back)
      : this.editMode
        ? process.env.REACT_APP_BACKEND_BASE_URL +
          this.state.insurance.backImageUrl
        : placeholder;

    const optionalImg = this.state.insurance.optional
      ? URL.createObjectURL(this.state.insurance.optional)
      : this.editMode && this.state.insurance.optionalImageUrl
        ? process.env.REACT_APP_BACKEND_BASE_URL +
          this.state.insurance.optionalImageUrl
        : placeholder;

    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Nombre del seguro"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.insurance.name,
                    onChange: event => this.inputChangedHandler(event, 'name')
                  }}
                />
              </GridItem>
            </GridContainer>
            <h2 style={{ margin: '2rem 0' }}>Imagen 1</h2>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} md={6}>
                <img src={frontImg} alt="Imagen 1" style={{ width: '350px' }} />
              </GridItem>
              <GridItem xs={12} md={6}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event, 'front')}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  style={{
                    fontSize: '1rem',
                    margin: '1rem 0'
                  }}
                />
              </GridItem>
            </GridContainer>
            <h2 style={{ margin: '2rem 0' }}>Imagen 2</h2>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} md={6}>
                <img src={backImg} alt="Imagen 2" style={{ width: '350px' }} />
              </GridItem>
              <GridItem xs={12} md={6}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event, 'back')}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  style={{
                    fontSize: '1rem',
                    margin: '1rem 0'
                  }}
                />
              </GridItem>
            </GridContainer>
            <h2 style={{ margin: '2rem 0' }}>Imagen 3</h2>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} md={6}>
                <img
                  src={optionalImg}
                  alt="Imagen 3 (opcional)"
                  style={{ width: '350px' }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event, 'optional')}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  style={{
                    fontSize: '1rem',
                    margin: '1rem 0'
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              style={{ margin: '0px auto 25px auto' }}
              onClick={this.handleSubmit}
            >
              {this.editMode ? 'ACTUALIZAR' : 'CREAR'} SEGURO
            </Button>
          </CardFooter>
        </Card>
        <Snackbar
          place="bl"
          color="success"
          icon={CheckCircleIcon}
          message={
            'El seguro fue ' +
            (this.editMode ? 'editado' : 'creado') +
            ' con éxito'
          }
          open={this.state.snackbar}
          closeNotification={() => this.setState({ snackbar: false })}
          close
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateInsurance: insurance => dispatch(actions.createInsurance(insurance)),
  onEditInsurance: insurance => dispatch(actions.editInsurance(insurance))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateInsurance);
