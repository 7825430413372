import * as actionTypes from '../actions/actionTypes'

const initialState = {
  branchOffices: null,
  error: null,
  loading: false,
  success: false,
  zones: null
}

const branchOfficesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BRANCH_OFFICES_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      }
    case actionTypes.FETCH_BRANCH_OFFICES_START:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.SET_BRANCH_OFFICES:
      return {
        ...state,
        branchOffices: action.branchOffices,
        error: false,
        loading: false,
      }
    case actionTypes.GET_BRANCH_OFFICES:
      return {
        ...state,
      }
    case actionTypes.FETCH_BRANCH_OFFICES_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }
    case actionTypes.CREATE_BRANCH_OFFICES_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        uploaded: true,
        createdAgilePurchasesCount: action.createdBranchOfficesCount,
        error: null,
      }
    case actionTypes.CREATE_BRANCH_OFFICES_CSV_FAILED:
      return {
        ...state,
        loading: false,
        uploaded: false,
        createdAgilePurchasesCount: action.createdBranchOfficesCount,
        error: action.error,
      }
    case actionTypes.SET_ZONES:
      return {
        ...state,
        zones: action.zones
      }
    default:
      return state
  }
}

export default branchOfficesReducer
